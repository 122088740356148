import { COOKIE_CONSENT_NAME } from '../utils/cookieConsent';
import { countryCheck, languageSwitcher } from './common';

const account = {
	title: 'User account settings',
	subtitle: 'If any of these details have changed, you can update them directly on this page. If you change '
		+ 'your name, we will need a\u00a0new valid document with your new name. If you wish to update your e-mail '
		+ '(e.g. due to a\u00a0name change), please e-mail us at <0>info@fondee.cz</0>.',
	tradingDay: 'Nearest trading day',
	faqLink: 'How and when do the investments take place',
	bankAccountsControl: {
		title: 'Bank accounts',
		withdrawal: 'Withdrawal account',
		withdrawalTooltip: 'We will send the funds designated for withdrawal to the selected account.',
		unconfirmedTooltip: 'The account is waiting to be verified. To allow us to verify that the bank account really belongs to you, '
			+ 'you first need to send a deposit from it, even 1\u00a0CZK is perfectly fine. Open the \u201cInvest more\u201d window to view '
			+ 'the payment instructions.',
		confirmChange: {
			title: 'Are you sure you want to change the withdrawal account?',
			text: 'If a previous withdrawal request is being processed, the change will not take effect until the next withdrawal.',
			submit: 'Yes, change it',
			cancel: 'Cancel',
		},
		addAccount: {
			button: 'Add another account',
			buttonTooltip: [
				'You can add another bank account for sending deposits and receiving funds. The account '
					+ 'must be held in your name. If you wish to add a foreign account, you must use the IBAN.',
				'$t(account.bankAccountsControl.addAccount.text.1)',
			],
			title: 'Add another account',
			text: [
				'Fill in the account prefix (if applicable), account number and bank code. The account must be held in '
				+ 'your name. If you wish to add a foreign account, you must use the IBAN.',
				'The account is not automatically active (e.g. for processing a withdrawal), and it is necessary to '
				+ 'verify it through a verification payment. You can send the whole deposit or just a few crowns or '
				+ 'euros. For payment instructions, click on \u201cInvest money\u201d.',
			],
			submit: 'Add account',
			cancel: 'Cancel',
		},
	},
	cashControl: {
		title: 'Cash ratio in the portfolio',
		titleSet: 'The cash ratio is set at {{ amount }}.',
		titlePlanned: 'The cash ratio is currently set at {{ current }}. When the portfolio is next rebalanced, '
			+ 'the cash ratio will be set at {{ planned }}.',
		titleTooltip: 'The standard approach is that at least 2% of\u00a0the value of your portfolio remains in\u00a0cash '
			+ '(in the case of\u00a0smaller portfolios, this rate can be higher). Here, you can submit a request for us to keep a larger part '
			+ 'of your portfolio in\u00a0cash when it is next rebalanced (the minimum being\u00a02%). This '
			+ 'ratio will be maintained every time the portfolio is rebalanced, until you send us a request to change it. The cash is kept '
			+ 'in\u00a0EUR and\u00a0just like your portfolio, it is subject to currency risk. The monthly fee is calculated '
			+ 'based on\u00a0the total value of the portfolio including the cash held.',
		button: 'Modify the cash ratio',
		buttonTooltip: 'The cash ratio will be modified on the next trading\u00a0day.',
		closedTooltip: 'The\u00a0cash ratio cannot be modified at the moment because a portfolio realignment is currently underway.',
		modal: {
			title: 'Apply the new ratio',
			text: 'The standard approach is that at least 2% of\u00a0the value of your portfolio remains in\u00a0cash (in the case of\u00a0smaller '
				+ 'portfolios, this rate can be higher). Here, you can submit a request for us to keep a larger part of your portfolio '
				+ 'in\u00a0cash (the minimum being 2%). This ratio will be maintained every time the portfolio '
				+ 'is rebalanced, until you send us a request to change it. The cash is kept in\u00a0EUR and\u00a0just like your portfolio, '
				+ 'it is subject to currency risk. The monthly fee is calculated based on\u00a0the total value of the portfolio including the cash held.',
			maxTooltip: 'In this case, you will not have any ETFs and everything will remain in cash.',
			submit: 'Apply the new ratio',
			cancel: 'Cancel',
		},
	},
	currencySelect: {
		title: 'Portfolio currency',
		subtitle: 'Currency in\u00a0Overview',
	},
	depositDefaultPortfolio: {
		title: 'Primary portfolio for\u00a0deposits',
		subtitle: 'Select the\u00a0portfolio to which we should credit the\u00a0deposit if the\u00a0deposit does not have '
			+ 'the\u00a0correct beneficiary note.',
		label: 'Selected portfolio',
		noOptionsMessage: 'No portfolios',
	},
	deviceTokens: {
		button: 'Logged in mobile devices',
		modal: {
			title: 'Logged in mobile devices',
			text: 'Mobile devices that have biometric login enabled. Biometric login is valid for 3\u00a0months. Disconnect '
				+ 'the\u00a0device to force a\u00a0password reset at the\u00a0next login.',
			disconnect: 'Disconnect',
			table: {
				device: 'Device name',
				active: 'Last active',
			},
		},
	},
	investorProfileControl: {
		title: 'Current investor profile:',
		titlePlanned: 'Planned investor profile:',
		titleTooltip: 'The investor profile based on which the ETFs in your portfolio are currently distributed. If you wish to realign your '
			+ 'portfolio on the basis of a different investor profile, click the Change button.',
		titlePlannedTooltip: 'The ETFs in your portfolio will be distributed based on this profile starting with the next rebalancing date. '
			+ 'If you wish to change the selection of the new profile, click the Change button.',
		button: 'Change',
		closedTooltip: 'The cash ratio cannot be changed at the\u00a0moment because a portfolio realignment is currently underway.',
		modal: {
			title: 'Select new profile',
			submit: 'Change profile',
			cancel: 'Keep the existing profile',
			cancelAllProfilesCheck: 'Keep recommended profile',
		},
		changeWarning: {
			title: 'Changing the investment profile',
			subtitle: 'Changing the investment profile can lead to tax liability. Are you sure you want to make the change?',
		},
	},
	passwordChange: {
		button: 'Change password',
		title: 'Password change',
		success: 'The password has been changed successfully!',
	},
	personalData: {
		button: 'Save changes',
		modalInfo: {
			title: 'Personal information check',
			text: '<0>We have the following personal information:</0>'
				+ '<1><0>{{ firstName }} {{ lastName }}</0>'
				+ '<0>{{ streetName }} {{ streetNumber }}, {{ city }} {{ postalCode }}</0>'
				+ '<0>{{ phonePrefix }} {{ phoneNumber }}</0></1>'
				+ '<0>Is this information correct?</0>',
			confirm: 'Yes, it is correct',
			edit: 'No, I want to update it',
		},
		modalNameChange: {
			title: 'Name change',
			text: 'Because you have requested a\u00a0name change, we must ask you for a\u00a0copy of your current ID.',
			button: 'Upload document',
		},
		phoneChange: {
			title: 'Verification of the new number',
			codeSendError: 'The verification code has been sent. If you need a new one, please wait at least one\u00a0minute.',
			codeSendSuccess: 'The verification code has been resent.',
			confirmText: 'We have sent a\u00a0confirmation code to the new phone number, <0>{{ phoneNumber }}</0>. '
				+ 'To continue, copy the code from the SMS and confirm with the button below.',
			cancelText: 'If you have entered an incorrect phone number, you can cancel the <0>change</0>.'
				+ 'The new code can be sent after 1\u00a0minute.',
			confirm: 'Confirm',
			sendAgain: 'Send the code again',
		},
		dataChange: {
			title: 'Confirmation of personal data',
			text: 'You have changed the following information:',
			subText: 'You can either confirm the changes or go back.',
			confirm: 'Confirm',
			cancel: 'Back',
			fields: {
				firstName: 'Name',
				lastName: 'Surname',
				phonePrefix: 'Prefix',
				phoneNumber: 'Phone number',
				streetName: 'Street',
				streetNumber: 'House number',
				city: 'City',
				postalCode: 'Post code',
				contactAddressStreetName: 'Contact street',
				contactAddressStreetNumber: 'Contact house number',
				contactAddressCity: 'Contact city',
				contactAddressPostalCode: 'Contact Post code',
			},
		},
	},
	profilePreference: {
		title: 'Message and survey settings',
		subtitle: 'You would like your e-mail statements to be sent:',
	},
	investingQuestionaire: {
		title: 'Investment questionnaire',
		lastFill: 'Date last completed: ',
		possibleFill: 'Next possible completion date: ',
		tooltip: 'The investment questionnaire can be filled in at most once every 6\u00a0months. '
			+ 'If there has been a change in your circumstances and you wish to complete the questionnaire earlier,'
			+ 'please contact us at\u00a0info@fondee.cz.',
		now: 'NOW',
		fill: 'Complete the investment questionnaire',
		modal: {
			title: 'Fill in the investment questionnaire',
			text: 'The result of the investment questionnaire affects the options for setting up your portfolios. '
			+ 'According to your answers and evaluation of the investment questionnaire, we can offer you riskier profiles than you have now,'
			+ 'or your profile may decrease or remain the same.',
			confirm: 'I understand, fill out the questionnaire',
		},
	},
	twoFactorAuthenticationControl: {
		title: 'Two-factor authentication',
		active: 'Two-factor authentication is active at the moment.',
		disable: 'Deactivate',
		disabled: 'Successfully deactivated',
		info: {
			title: 'Procedure:',
			line1: 'Download an OTP generator, for example Google Authenticator (<0>App Store</0>, <1>Google Play</1>)',
			line2: 'Scan the QR\u00a0code',
			line3: 'Enter the single-use code from the\u00a0application',
			line4: 'Click the \u201cActivate\u201d button',
		},
		code: {
			label: 'Single-use code',
			error: 'Wrong single-use code',
		},
		activate: 'Activate',
		activated: 'Successfully activated',
	},
	withdrawals: {
		closureTitle: 'Account closure',
		activeTitle: 'Orders entered',
		withdrawals: {
			title: 'Cash withdrawal from your investment account',
			disabledMessage: 'Can only be entered by the owner of the specified bank account.',
			part: {
				title: 'Cash withdrawal',
				text: 'Cash withdrawal means we give the broker an order to sell a part of your portfolio, we convert the money '
					+ 'to\u00a0the required currency and\u00a0send it to\u00a0the bank account designated as the main one. The ETFs will be '
					+ 'sold based on their proportional distribution in the\u00a0portfolio so that your portfolio has an optimal distribution of ETFs '
					+ 'again after the sale. The ETFs are listed in\u00a0EUR and\u00a0the exchange rate applicable at\u00a0the time of the sale '
					+ 'will be used\u00a0for the conversion to CZK. If the entered sum exceeds the current value of the ETFs, the '
					+ 'whole portfolio will be sold and\u00a0the cash (minus the monthly fees) will be sent to\u00a0your\u00a0account.',
				overError: 'The entered amount is greater than the\u00a0current portfolio value.',
				activeText: 'You have entered an\u00a0instruction to\u00a0withdraw {{ sum }}.',
				activeTextTransfer: 'You have entered an\u00a0instruction to\u00a0transfer {{ sumTransfer }} to\u00a0portfolio {{ portfolio }}.',
				activeTextTransferMultiple: 'You have made instructions to\u00a0make a\u00a0transfer from this portfolio:',
				activeTextTransferMultiplePart: '{{ sumTransfer }} to\u00a0portfolio {{ portfolio }}',
			},
			all: {
				title: 'Withdraw everything',
				text: 'Withdrawing all the available funds means that on the\u00a0nearest trading day, we will give the broker an order '
					+ 'to\u00a0sell all the ETFs you hold (if you have any). We will subsequently convert all the available funds '
					+ 'and\u00a0send them to your bank account. The ETFs are listed in\u00a0EUR and\u00a0the exchange rate applicable at\u00a0the '
					+ 'time of\u00a0the sale will be used for the conversion to CZK. If you choose this option, you will be withdrawing all the available funds '
					+ 'from your account, but the Fondee account itself will not be closed.',
				activeText: 'You have given an\u00a0instruction to sell all ETFs and withdraw all funds from the\u00a0portfolio.',
			},
			confirm: 'Confirm',
			success: 'The withdrawal request has been submitted.',
			cancel: 'Cancel the request',
			cancelOnlyParent: 'Some orders can only be cancelled by the legal representative.',
			currencyWarning: 'Selected withdrawal currency does not match the\u00a0currency of\u00a0the\u00a0portfolio.',
			childPortfolioWarning: 'If you cancel the children\'s portfolio prematurely, you will not comply with the\u00a0'
				+ 'conditions for receiving a\u00a0discounted fee. We will therefore deduct the\u00a0difference between the\u00a0fee '
				+ 'you have already paid and our normal fee.',
			confirmPopup: {
				title: 'Withdrawal confirmation',
				text: 'Please confirm that you really want to\u00a0make this withdrawal.',
				button: 'Yes, withdraw money',
				twoFactorAuthentication: 'Enter your one-time password',
			},
			allPopup: {
				title: 'Cash withdrawal from your investment account',
				text: 'The entered amount is very close to the total value of your portfolio. Would you like to withdraw all your funds?',
				button: 'Yes, withdraw all',
				secondaryButton: 'No, withdraw only the\u00a0required amount',
			},
			error: {
				title: 'Warning',
				text: 'The withdrawal cannot be entered because you already have an order to withdraw all funds, cancel the account '
					+ 'or move part of the\u00a0portfolio. In order to enter a\u00a0withdrawal command, you must first cancel the\u00a0previous commands',
				button: 'Close',
			},
			childWarning: '<0>You can withdraw a\u00a0maximum of 10% per year of the total value of all children\'s portfolios.</0>',
			childRatioError: 'You can withdraw a\u00a0maximum of 10% per year of the total value of all children\'s portfolios.',
			pensionWarningTitle: 'Pension portfolio',
			pensionWarning: '<0>The conditions for a\u00a0withdrawal from the pension account were not met. In order to '
				+ 'enter a\u00a0withdrawal, it is necessary to be at least 60\u00a0years of age and at the same time at least '
				+ '10\u00a0years (120\u00a0months) must have passed since the creation of the pension portfolio.</0>'
				+ '<0>The only option is to cancel the portfolio, but please note that in this case fees will '
				+ 'be charged up to the standard amount.</0>',
		},
		cancel: {
			portfolio: {
				title: 'Portfolio cancellation',
				disabledMessage: 'Only the legal representative can cancel the portfolio',
				text: 'If you want to cancel this portfolio, we will submit an\u00a0order to the\u00a0broker to sell all ETFs from this '
					+ 'portfolio. Cash will then (after deducting the monthly fees) be converted to the\u00a0currency you chose when you '
					+ 'entered the\u00a0withdrawal and sent to the\u00a0bank account you have marked as a\u00a0selection account. '
					+ 'The\u00a0ETFs will be sold on the\u00a0next trading day after submitting the\u00a0order. We will then send '
					+ 'the\u00a0money to your account. The whole process can take up to a\u00a0week.',
				subtext: 'Even if your portfolio is empty, it will be canceled on the\u00a0next business day.',
				note: '* We recommend that you select the\u00a0"Withdraw all funds" option instead of completely withdrawing your '
					+ 'portfolio. This will give you the\u00a0full value of the\u00a0portfolio, but if you ever want to\u00a0invest with '
					+ 'Fondee again in\u00a0the\u00a0future, you do not need toset up a\u00a0new portfolio.',
				confirm: 'Cancel portfolio',
				success: 'Portfolio cancellation successfully entered.',
				activeText: 'You have given an\u00a0instruction to withdraw all funds and cancel the\u00a0portfolio.',
				account: {
					title: 'Account cancellation entered',
					closure: 'You have entered an\u00a0order to sell ETFs from all portfolios, withdraw all funds and cancel the\u00a0account. '
						+ 'You can cancel the\u00a0order on the\u00a0<0>My Account</0> page.',
					child: {
						closure: 'You´ve placed an\u00a0order to sell ETFs from all portfolios, withdraw all funds and cancel account. '
							+ 'The\u00a0order may be cancelled only by parents on their <0>My Account</0> page.',
					},
				},
				cancel: 'Cancel order',
				popup: {
					title: 'Portfolio cancellation',
					text: 'Please confirm that you really wish to\u00a0cancel your portfolio with Fondee.',
					button: 'Yes, withdraw money and cancel the\u00a0portfolio',
					twoFactorAuthentication: 'Enter your one-time password',
				},
				error: {
					title: 'Warning',
					text: 'The portfolio cancellation cannot be entered because you already have an order to withdraw all funds, cancel the account '
						+ 'or transfer of the\u00a0entire portfolio. In order to enter a\u00a0withdrawal command, you must first cancel the\u00a0previous commands',
					button: 'Close',
				},
				transferWarning: {
					title: 'Warning',
					text: 'You have placed an\u00a0order to move cash or ETFs to this portfolio. Either cancel this order or place an\u00a0order '
						+ 'to cancel the\u00a0portfolio after the\u00a0move has been made.',
					button: 'I understand',
				},
				defaultPortfolioSelect: {
					title: 'Warning',
					text: 'You have requested the\u00a0cancellation of the\u00a0default portfolio for deposits. '
						+ 'Please select a\u00a0new portfolio for deposit allocation.',
					textChild: 'You have requested the\u00a0cancellation of the\u00a0portfolio you set up as your child\'s default deposit '
						+ 'portfolio. Please select a\u00a0new portfolio for deposit allocation. You can choose '
						+ 'from the\u00a0active portfolios of this user.',
					textBoth: 'You have requested a cancellation of the\u00a0portfolio that you and your child have '
						+ 'set up as the\u00a0default portfolio for deposits. Please select new portfolios for each user.',
					button: 'Confirm',
					label: 'Please select your new default portfolio for deposits',
					labelChild: 'Please choose a\u00a0new default portfolio for the\u00a0child',
				},
			},
			title: 'Withdraw all portfolios and cancel account',
			text: 'If you want to stop investing, we will instruct the broker to sell all ETFs in your portfolios. If you have '
				+ 'an\u00a0active children´s portfolios, these will be also cancelled. The\u00a0cash will then be sent (less monthly '
				+ 'fees) to your primary bank account for withdrawals. The whole process can take up to a\u00a0week. In\u00a0case '
				+ 'you wish to withdraw your funds in\u00a0CZK, the balance will be converted according to the\u00a0current exchange '
				+ 'rate. You may place an\u00a0order to cancel your investment account at any time, we will sell the\u00a0investments '
				+ 'on\u00a0the\u00a0nearest trading day.',
			textChild: 'Cancellation of the\u00a0investment account can be entered by the\u00a0parent only. When you come of '
				+ 'age, all rights are transferred to you, so you will have full control over the\u00a0account, including its cancellation.',
			note: 'We recommend withdrawing all the\u00a0money from the\u00a0portfolio (or portfolios) instead of completely '
				+ 'cancelling the\u00a0account. You can simply enter the\u00a0order in\u00a0the\u00a0details of the\u00a0specific '
				+ '<0>portfolio</0>. This will give you the full value of the\u00a0portfolio, but if you want to invest again with '
				+ 'Fondee at some point in\u00a0the\u00a0future, you do not need to open an\u00a0account again.',
			confirm: 'Confirm',
			success: 'Account cancellation successfully entered.',
			activeTitle: 'Account cancellation entered',
			activeText: 'You have entered an\u00a0order to sell ETFs from all portfolios, withdraw all funds and cancel the\u00a0account.',
			activeTextChild: 'You´ve placed an\u00a0order to sell ETFs from all portfolios, withdraw all funds and cancel account. '
				+ 'The\u00a0order may be cancelled only by parents on their My Account page.',
			cancel: 'Cancel the order',
			childClosure: 'When you have given an order to cancel a child\'s account, you cannot cancel your own account.',
			popup: {
				title: 'Account cancellation',
				text: 'Please confirm that you really wish to\u00a0cancel your investment account with Fondee.',
				button: 'Yes, withdraw money and cancel the account',
				twoFactorAuthentication: 'Enter your one-time password',
			},
		},
		transfer: {
			title: 'Transfer between portfolios',
			portfolioLabel: 'Transfer to\u00a0portfolio:',
			direct: {
				title: 'Sell and transfer cash',
				text: 'Here you can enter the\u00a0amount in\u00a0euros that you want to move to a\u00a0different portfolio. This '
					+ 'means that on the\u00a0next trading day, we will submit an\u00a0order to the\u00a0broker to sell the\u00a0appropriate '
					+ 'portion of\u00a0the\u00a0ETFs and transfer the\u00a0specified euro amount to another portfolio of\u00a0your choice. '
					+ 'The\u00a0ETFs will be sold according to their proportional representation in\u00a0the\u00a0portfolio so that your '
					+ 'portfolio is optimally allocated after the sale. We will transfer the\u00a0amount in\u00a0euros to avoid '
					+ 'unnecessary exchange to\u00a0and from Czech\u00a0crowns. If the\u00a0amount entered exceeds the\u00a0current value '
					+ 'of the\u00a0ETFs, the\u00a0entire portfolio will be sold and the\u00a0cash transferred to the\u00a0selected portfolio.',
				transferWarning: 'You have placed an\u00a0order to sell and transfer cash. Therefore, you cannot place an\u00a0order '
					+ 'to\u00a0transfer a\u00a0part of the\u00a0portfolio without selling ETFs. To\u00a0place an\u00a0order to\u00a0transfer '
					+ 'without selling ETFs, cancel the\u00a0existing order.',
			},
			all: {
				title: 'Sell all and move cash',
				text: 'Selling everything and moving the\u00a0cash means that on\u00a0the\u00a0next trading day, we will give '
					+ 'the\u00a0broker an\u00a0order to sell all ETFs in\u00a0this portfolio and transfer the\u00a0cash in\u00a0euros to '
					+ 'another portfolio of your choice. We will transfer the\u00a0amount in\u00a0euros to avoid unnecessary exchange '
					+ 'to\u00a0and from Czech\u00a0crowns.',
				allPopup: {
					title: 'Move cash between portfolios',
					text: 'The amount entered is very close to the\u00a0total value of your portfolio. Do you wish to move all of your funds?',
					button: 'Yes, move all',
					secondaryButton: 'No, move only the\u00a0requested amount',
				},
				activeText: 'You have given an\u00a0instruction to sell all ETFs in the\u00a0portfolio and move cash into '
					+ 'portfolio {{ portfolio }}',
			},
			part: {
				title: 'Transfer a\u00a0part of the\u00a0portfolio without selling ETFs',
				text: 'Moving a\u00a0portion of the\u00a0portfolio without selling ETFs means that on\u00a0the\u00a0next trading day, '
					+ 'we will calculate the\u00a0number of units corresponding to the\u00a0specified ratio (rounded down) for each ETF you '
					+ 'have in\u00a0the\u00a0portfolio. Next, we will calculate the\u00a0ratio of cash in\u00a0the\u00a0portfolio '
					+ '(for each currency) corresponding to the\u00a0desired ratio (rounded up to the\u00a0hundredths). We then move '
					+ 'the\u00a0calculated ETF and cash ratios to the\u00a0selected portfolio. Thus, no ETF sales will occur.',
				activeText: 'You have given an\u00a0instruction to move {{ sum }} of the\u00a0ETFs and cash from this portfolio to '
					+ 'the\u00a0portfolio {{ portfolio }}.',
				error: {
					title: 'Warning',
					text: 'The specified portfolio ratio is too high. Enter a\u00a0lower ratio or cancel previously entered orders to '
						+ 'move part of the\u00a0portfolio.',
					button: 'Close',
				},
				transferWarning: 'You have placed an\u00a0order to transfer a\u00a0part of the\u00a0portfolio without selling ETFs. '
					+ 'Therefore, you cannot place an\u00a0order to sell and transfer cash. To place an\u00a0order to transfer cash, '
					+ 'cancel the\u00a0existing order.',
			},
			activeTitle: 'Transfer of funds to\u00a0another portfolio',
			cancel: 'Cancel the order',
			confirm: 'Confirm',
			childWarning: 'You can only move funds between your child\'s portfolios. You cannot withdraw money from your '
				+ 'child\'s portfolio to your personal account.',
			popup: {
				title: 'Warning',
				text: 'Please confirm that you really want to make the\u00a0desired transfer.',
				textChild: 'Please confirm that you really want to make the\u00a0entered transfer. Please note that funds moved to '
					+ 'a\u00a0child\'s portfolio cannot be sent back to the\u00a0parent\'s portfolio. Withdrawals from children\'s portfolios '
					+ 'to a\u00a0bank account can be made up to 10% of the\u00a0portfolio\'s value per year. If you wish to withdraw '
					+ 'the\u00a0entire amount, you must cancel the\u00a0child portfolio.',
				button: 'Yes, confirm transfer',
				twoFactorAuthentication: 'Enter your one-time password',
			},
			error: {
				title: 'Warning',
				text: 'You cannot enter a\u00a0transfer because you have already entered an\u00a0order to withdraw all funds, '
					+ 'cancel the\u00a0account, or move the\u00a0entire portfolio. In order to enter a\u00a0transfer order, you must '
					+ 'first cancel the\u00a0previous orders.',
				button: 'Close',
			},
		},
	},
	childList: {
		title: 'My children',
		text: 'Here you can manage your children\'s accounts, complete their registration or cancel it if necessary.',
		finishOnboarding: 'Complete registration',
		accountClosure: 'You have entered a\u00a0cancellation of your account, it is not possible to abort '
			+ 'the\u00a0cancellation of the\u00a0child\'s account.',
		personalInformation: {
			title: "Child's personal information",
			confirm: 'Save changes',
			cancelAccount: 'Cancel the child\'s account',
		},
	},
	childClosure: {
		cancel: {
			title: 'Account cancellation',
			text: '<0>Please confirm whether you really wish to cancel your Fondee child\'s account. In\u00a0case of early '
				+ 'cancellation you will not meet the\u00a0conditions for the\u00a0discounted fee. Therefore, according to the\u00a0terms '
				+ 'of the\u00a0contract, before payment we will deduct the\u00a0difference between the\u00a0fee you have paid so far '
				+ 'and our regular fee.</0>'
				+ '</0>After selling your ETFs we will send the\u00a0amount to your primary bank account for withdrawals.'
				+ 'The whole process can take up to a\u00a0week. In\u00a0case you wish to withdraw your funds in\u00a0CZK, '
				+ 'the\u00a0balance will be converted according to the\u00a0current exchange rate. You may place an\u00a0order to '
				+ 'cancel your investment account at\u00a0any time, we will sell the\u00a0investments on\u00a0the\u00a0nearest '
				+ 'trading day. We recommend withdrawing all the\u00a0money from the portfolio (or portfolios) instead of completely '
				+ 'cancelling the\u00a0account. You can simply enter the\u00a0order in\u00a0the\u00a0details of the specific <0>portfolio</0>. '
				+ 'This will give you the\u00a0full value of the\u00a0portfolio, but if you want to invest again with Fondee at some '
				+ 'point in the\u00a0future, you do not need to open an\u00a0account again.</0>',
			confirm: 'I understand, cancel the children\'s account',
			warning: 'A\u00a0request has been made to cancel the\u00a0account',
		},
	},
	onboardingClosure: {
		title: 'Cancel registration',
		text: 'Please confirm whether you really wish to cancel child\'s registration.',
		cancel: 'Cancel children\'s registration',
		confirm: 'I understand, cancel',
	},
	employerList: {
		title: 'Employers contributing to my DIP',
	},
};

const ageCheckBlock = {
	title: 'Warning',
	text: 'We are sorry, but you do not meet the age limit for investing with Fondee. '
		+ 'We look forward to welcoming you again when you are 18 years old. If you wish to cancel '
		+ 'your registration in the meantime, you can do so via the link below.',
	button: 'Cancel the account',
	aborted: 'Your account has been canceled.',
};

const allPortfoliosCheck = {
	legend: {
		negative: 'Current profile',
		positive: 'Planned change at the next trading',
	},
	table: {
		title: 'Portfolio name',
	},
	recommend: 'Based on your answers in the investment questionnaire, we evaluated the profiles in the\u00a0<0>green</0> interval as '
			+ 'suitable for you.',
	recommendWarning: 'Profiles in the\u00a0grey interval are not suitable for you according to the evaluation of the '
			+ 'investment questionnaire.',
	confirm: 'Confirm',
	reset: 'I wish to go over the answers in\u00a0the investment questionnaire ',
};

const anchors = {
	about: 'what-is-fondee',
	accountClosure: 'account-closure',
	chart: 'graph',
	comparison: 'comparison',
	contactForm: 'contact-us',
	employerList: 'my-employers',
	faq: 'faw',
	ourApproachTeam: 'our-team',
	portfolio: 'portfolio',
	portfolioSettings: 'portfolio-settings',
	portfolioWithdrawals: 'portfolio-orders',
	products: 'products',
	rebalancing: 'rebalancing',
	studentConfirmation: 'student-confirmation',
};

const appBanner = {
	title: 'Fondee',
	author: 'Fondee a.s.',
	downloadGoogle: 'FREE - In\u00a0Google Play',
	downloadIOS: 'FREE - On the\u00a0App Store',
	visit: 'View',
};

const blog = {
	meta: {
		title: 'Fondee blog',
		description: 'Everything you ever wanted to know about investing, but were afraid to ask.',
	},
	back: '\u2190\u00a0Back',
	comingSoon: 'Come back soon, new articles will be waiting for you.',
	title: 'Fondee blog',
	text: 'Everything you ever wanted to know about investing, but were afraid to ask.',
	menu: {
		title: 'Article filter',
		search: {
			label: 'Search article',
			empty: 'No article found',
		},
		tags: {
			title: 'Filter by tags',
		},
		topRead: {
			title: 'Most read articles',
			button: 'Show most read',
		},
	},
};

const blogPost = {
	meta: {
		titleSuffix: ' - Fondee blog',
	},
	updated: 'Updated',
	share: {
		title: 'Did you like the article?',
		description: 'Share it with your friends',
		button: 'Share on ',
	},
	list: {
		button: 'View more articles',
	},
	recommended: {
		title: 'Other related articles',
	},
	references: {
		title: 'Sources:',
	},
	content: {
		title: 'Article content',
	},
};

const campaign = {
	calculation: {
		fondee: {
			enumeration: 'appreciation at Fondee\u00a0- inflation rate\u00a0= real appreciation',
			enumarationResult: '6%\u00a0- 3.5%\u00a0= 2.5%',
			comparation: 'During the same time period, your deposit on a savings account would decrease by up to\u00a011,500\u00a0CZK because of the low interest.',
			info: 'Every year, your savings will in fact appreciate by\u00a02,5%. For a deposit of 100,000\u00a0CZK, this adds up to: <0>+13,500\u00a0CZK</0> in five years',
			link: 'Show the calculation for a savings account',
		},
		account: {
			enumeration: 'interest on your savings account\u00a0- inflation rate\u00a0= real appreciation',
			enumarationResult: '1%\u00a0- 3.5%\u00a0= -2.5%',
			comparation: 'During the same time period, your deposit at Fondee could, thanks to a higher interest, grow by\u00a013,140\u00a0CZK on\u00a0average.',
			info: 'This means you are in fact losing\u00a02.5% of your savings every year. For a deposit of 100,000\u00a0CZK, this is a decrease of: <0>-11,500\u00a0CZK</0> in five years.',
			link: 'Show the calculation for Fondee',
		},
		title: 'Easy mathematics',
		button: 'I want to try Fondee FREE OF CHARGE',
	},
	calculator: {
		button: 'Calculate',
		fields: {
			deposit: {
				label: 'Deposit',
				error: 'The deposit value must be filled in.',
				errorRange: 'The deposit value must be between 1,000\u00a0CZK and 10,000,000\u00a0CZK.',
			},
			inflationRate: {
				label: 'Inflation rate',
				error: 'The inflation rate value must be filled in.',
				errorRangeMin: 'It is not very likely that the inflation rate would drop under 1%.',
				errorRangeMax: 'Inflation is not really likely to ever reach such a high value. At least we hope not.',
			},
			interestRate: {
				fondee: {
					label: 'Appreciation',
					error: 'Appreciation has a constant value of\u00a08%.',
				},
				account: {
					label: 'Interest on a savings account',
					error: 'The interest on a savings account value must be filled in.',
					errorRange: 'The interest on a savings account value must be between 0 and 10%.',
				},
			},
			years: {
				fondee: {
					label: 'Investment period (in years)',
					error: 'The investment period value must be filled in.',
					errorRange: 'The investment period value must be between 1\u00a0year and 50\u00a0years.',
					errorFormat: 'The investment period value must be a whole number.',
				},
				account: {
					label: 'Savings period (in years)',
					error: 'The savings period value must be filled in.',
					errorRange: 'The savings period value must be between 1\u00a0year and 50\u00a0years.',
					errorFormat: 'The savings period value must be a whole number.',
				},
			},
		},
		how: 'How come?',
		result: {
			info: 'The real value of money:',
			value: '{{ value }}',
			fondee: 'Wow, great! Your deposit will grow by\u00a0<0>{{ value }}</0> on\u00a0average.',
			fondeeComparation: 'On a savings account, up to {{ value }} will simply be\u00a0lost and gone forever.',
			account: 'On a <0>savings account</0>, your deposit will <0>decrease its value</0> by as much as\u00a0<0>{{ value }}</0>.',
			accountComparation: 'With\u00a0<0>Fondee</0>, it would appreciate in value by approximately\u00a0<1>{{ value }}</1>, '
				+ 'including the effect of inflation!',
		},
		subtitle: 'How do your savings actually appreciate in value?',
		title: 'An honest savings calculator',
		toggle: {
			fondee: 'With Fondee',
			account: 'On a savings account',
		},
	},
	explanation: {
		account: {
			info: 'The reason is that the interest on a savings account is lower than the inflation rate. This is why your money loses its value on a savings account.',
			title: 'Why is it you are losing money on a savings account?',
			vocabulary: {
				title: 'Simple vocabulary',
				collapse: {
					1: {
						name: 'Interest on a savings account',
						description: 'The percentage by which your deposit on a savings account will grow over the course of one\u00a0year',
					},
					2: {
						name: 'Inflation rate',
						description: 'The percentage by which the value of a currency will decrease over the course of one\u00a0year',
					},
				},
			},
		},
		fondee: {
			info: 'The reason is that with\u00a0Fondee, the appreciation rate of your investments is higher than the inflation rate. This is why with\u00a0Fondee, your money keeps growing.',
			title: 'How come the money appreciates in value at Fondee?',
			vocabulary: {
				title: 'Simple vocabulary',
				collapse: {
					1: {
						name: 'Appreciation with Fondee',
						description: 'An appreciation of 6% per annum is an example based on historical data that more or less corresponds with stock portfolios. '
							+ 'You may view specific portfolios and\u00a0their historical appreciations <0>here</0>.',
					},
					2: {
						name: 'Inflation rate',
						description: 'The percentage by which the value of a currency will decrease over the course of one\u00a0year',
					},
				},
			},
		},
	},
	hero: {
		button: 'Open a Fondee account',
		subtitle: 'Your money doesn\'t have to be losing its value on\u00a0savings\u00a0accounts.',
		title: 'Stop saving. Invest!',
	},
	investWithFondee: {
		button: 'I want to try Fondee FREE OF CHARGE',
		list: {
			1: '<0>A historically higher interest</0> than you will ever get on a savings account',
			2: '<0>Literally anyone can do it</0>, because we do the investing for you',
			3: '<0>3-month</0> free trial',
		},
		more: 'Find out more about\u00a0Fondee',
		title: 'Invest with\u00a0Fondee',
	},
};

const childAgeCheck = {
	modal: {
		title: 'Your child is coming of age',
		text: 'When the child reaches maturity, his/her portfolio will be transferred under his/her own management, '
			+ 'but in\u00a0order for this to happen, the\u00a0necessary information mentioned below must be filled in. Otherwise, '
			+ 'the\u00a0portfolio will stay under your full management.',
		checkbox: 'I confirm that my child\'s contact details are correct and up to date.',
		button: 'Confirm details',
	},
};

const clientCheck = {
	description: '<0>On this page, in addition to your own regular checks, you will also find checks for your children, '
		+ 'which need to be completed once in a while.</0><0> If the\u00a0check remains incomplete, we will restrict your access '
		+ 'to the\u00a0client zone, as well as the access of the child (if they have one). In case of any questions, do '
		+ 'not hesitate to contact us at <0>info@fondee.cz</0>.</0>',
	childDescription: 'In order to restore access to the client zone, it is necessary to complete a periodical check of the '
		+ 'child\'s account. The check must be done by the parent in their client zone on the Client Check page.',
	questionnaire: {
		aml: {
			title: 'Anti money laundering',
			text: 'In order to continually protect your and other clients\' assets, we need to check from time to time that '
				+ 'all your information is up to date. So let\'s do it, the process will only take a\u00a0few minutes.',
			start: 'Fill out the questionnaire',
			closeAccount: {
				title: 'Account cancellation',
				text: 'Select the\u00a0currency',
				button: 'Cancel account',
			},
		},
		investment: {
			title: 'Review of the investment questionnaire',
			text: 'From time to time we have to check whether your investment circumstances have changed. If so, it may have an impact on the '
				+ 'choice of your ideal investment profile. Please note that based on new answers, you may end up with a different profile than '
				+ 'the one you currently have selected. You can change your answers at any time before you finish.',
			warning: 'Please note that changes must be confirmed to complete the\u00a0check successfully. If you do not '
				+ 'confirm the\u00a0changes, your Client zone will be locked 30\u00a0days after initiating the\u00a0check. '
				+ 'If you have made a mistake in the\u00a0questionnaire, you can return to the answers.',
			start: 'Fill out the questionnaire',
		},
	},
	documents: {
		error: 'We are sorry, there was an\u00a0error while uploading the documents.',
		title: 'Upload a valid document',
		text: 'Please upload your current personal ID. After clicking on the button, we will redirect you to '
			+ 'our partner service, which handles the document processing for us. The whole process is encrypted '
			+ 'to ensure the safety of your personal data.',
		textChild: 'Please upload your child\'s personal ID. After clicking on the button, we will redirect you to '
			+ 'our partner service, which handles the document processing for us. The whole process is encrypted '
			+ 'to ensure the safety of your personal data.',
	},
	personalInformation: {
		title: 'Personal information check',
	},
	checkAt: 'Last check: <0>{{ date }}</0>',
	checkNext: 'Next check: <0>{{ date }}</0>',
	profileSelect: {
		prev: 'Previous',
		next: 'Next',
		myAccount: 'My account',
	},
	disabledTooltip: 'The action will be unlocked after completing the check',
};

const clientHeader = {
	faqLink: 'How and when do the investments take place',
	nextTradingDay: 'Nearest trading day',
	titles: {
		deposit: 'Deposit',
		depositAndWithdrawal: 'Deposit and withdrawal',
		trading: 'Trading',
		withdrawal: 'Withdrawal',
	},
};

const contact = {
	title: 'In need of a little more help?',
	text: 'Try looking into our FAQs or speak to a member of our investment consultants team.',
	contactFormAnchor: 'Go to the form',
	faqAnchor: 'Go to FQA',
	faqTitle: 'FAQ',
};

const contactForm = {
	button: 'Leave us your contact details<0></0>and\u00a0we\'ll call you',
	modal: {
		title: 'Any questions?',
		subtitle: 'Leave us your number and we\'ll call\u00a0you',
		submit: 'Call me',
		success: 'Thank you, we\'ll call\u00a0you soon!',
	},
};

const contactUs = {
	title: 'Haven\'t found the answers to your\u00a0questions?',
	text: {
		label: 'Your question',
		error: 'It is necessary to ask a question',
	},
	name: {
		label: 'Name and surname',
		error: 'Please enter your name.',
	},
	email: {
		label: 'E-mail',
		error: 'Please enter your e-mail address.',
	},
	conditions: {
		label: 'I agree with <0>the processing of my personal data</0> and\u00a0<1>the terms of use of this webpage</1>',
		error: 'This consent is necessary.',
	},
	submit: 'Submit question',
	success: 'Thank you for asking, we\'ll contact you soon!',
};

const cookieConsent = {
	message: '<0>Fondee\u00a0a.s. uses cookies to make its website more customizable to customers and easier to '
		+ 'use. We always process the necessary cookies without your consent, thus ensuring the proper functioning of '
		+ 'the website.</0><0>If you click on the "Accept All" button, you will give Fondee\u00a0a.s. consent to the storage '
		+ 'of analytical and marketing cookies. If you click on the "Accept only necessary" button, you will give the team '
		+ 'Fondee\u00a0a.s. consent to the storage of only functional cookies. If you click on "Detailed settings", you can '
		+ 'choose which cookie processing purposes you want to allow.</0><0>With the help of cookies, we and third parties may '
		+ 'collect information about you and monitor your behavior when browsing our and other sites. Based on this information, '
		+ 'we can tailor our site, our communications, and our ads to your interests and profile. In addition, these cookies allow '
		+ 'you to share information on social media.</0><0>For more information, see our <0>Privacy Policy</0> and <1>Cookie Policy</1>.</0>',
	controls: {
		details: 'Detailed settings',
		necessary: 'Accept only necessary',
		selected: 'Accept selected',
		all: 'Accept all',
	},
	categories: {
		functionality: {
			title: 'Functional cookies',
			text: 'We use them primarily to ensure that our website will function properly, to find out what your preferences are '
				+ 'and to make the website remember that you have logged in, and to monitor users in order to register them. The '
				+ 'necessary cookies are also used to optimize our website in real time according to how you use the website and to '
				+ 'load the website evenly. Without these files, the Website cannot function properly',
		},
		analytics: {
			title: 'Analytical cookies',
			text: 'These are used to analyze the use of the Website, to count visitors, to monitor the time of traffic '
				+ 'and to find out how the Website can be improved. We do not associate website usage statistics and other reports '
				+ 'with specific people',
		},
		marketing: {
			title: 'Marketing cookies',
			text: 'These are used to collect personal information about the user from a\u00a0marketing point of view. '
				+ 'E.g.\u00a0collect information in order to adapt the offered advertising to the customer\'s interests, '
				+ 'connection with social networks, transfer of information between affiliate partners,\u00a0etc.',
		},
	},
};

const cookies = {
	title: 'Principles of use of cookies',
	intro: '<0>These are the principles of using cookies (hereinafter referred to as the "<0>Principles</0>") on the '
		+ 'website of Fondee\u00a0a.s., with its registered office at Rumunská\u00a022/28, Vinohrady, 120\u00a000 '
		+ 'Prague\u00a02, ID\u00a0number: 06691862, entered in the Commercial Register kept by the Municipal Court in '
		+ 'Prague under file no.\u00a0No.\u00a0B\u00a027322 (hereinafter referred to as the “<0>Company</0>” or '
		+ '“<0>Administrator</0>”) and within the\u00a0Administrator’s mobile application called "Fondee Mobile Application" '
		+ '(hereinafter referred to as the\u00a0"<0>Application</0>") and apply to all users of the websites operated by the '
		+ 'Company (hereinafter referred to as the\u00a0“<0>Website</0>”) and to the\u00a0Application where applicable.</0>'
		+ '<0>The purpose of this Policy is to properly inform Website and/or Application users about the use of cookies and the possibility '
		+ 'of prohibiting their use, as well as their purpose to provide information on how Website and/or Application users\' data is processed '
		+ 'and used and how the Company processes information about Website users using cookies.</0>'
		+ '<0>The Company does not use cookies to collect personal data of users, however, the personal data that the Company '
		+ 'obtains through them may be combined with other personal data of users provided by users of the Company '
		+ 'or otherwise obtained by the Company. The company processes users\' personal data, including personal data '
		+ 'obtained through the use of cookies, in accordance with Regulation (EU) No.\u00a01907/2006 of the European Parliament '
		+ 'and of the Council.\u00a02016/679 of 27\u00a0April\u00a02016, on the protection of natural persons with regard to the '
		+ 'processing of personal data and on the free movement of such data, and related legislation on the protection of '
		+ 'personal data and the use of cookies.</0>',
	about: {
		title: 'What are cookies?',
		text: '<0>Cookies are short text files, which usually contain a\u00a0unique identifier and which are sent to '
			+ 'the browser of your terminal and are located in the storage of your terminal. This file contains some anonymous '
			+ 'information such as identifier, Website name, or duration (expiration). The file may be temporary and exists at '
			+ 'the time of your login to our Website or may be of a\u00a0permanent nature and remains in the storage of your '
			+ 'terminal equipment for a\u00a0specified period of time or until deleted by the user.</0>',
		app: {
			title: 'Use of cookies in the\u00a0Application',
			text: '<0>The Application uses functional cookies. Functional cookies are essential for the\u00a0Application to work '
				+ 'properly and at the\u00a0same time to remember that you have logged in. Thanks to functional cookies, we can monitor '
				+ 'the\u00a0flawless operation of the\u00a0application and prepare new versions of the\u00a0application, etc. '
				+ 'We always process data obtained thanks to functional cookies in an aggregated form, ie without identifying '
				+ 'a\u00a0specific user. Data processed using functional cookies will not be used for marketing or targeting purposes.</0>'
				+ '<0>We also use basic analytical tools in the\u00a0Application. However, no information is stored on your end '
				+ 'device, as is the\u00a0case with analytical cookies on the Website. This information only applies to '
				+ 'the\u00a0display of individual pages in the\u00a0Application and is fully anonymized and aggregated. '
				+ 'No information about a\u00a0specific user is collected or stored.</0>'
				+ '<0>By installing and using the\u00a0Application, you agree to the processing of functional cookies to '
				+ 'the\u00a0extent described above.</0>',
		},
	},
	types: {
		title: 'Types of cookies',
		texts: [
			{
				text: '<0>According to the source and control, we distinguish the following cookies:</0>',
				list: [
					'<0>First-party cookies</0> that are created by the Website. These cookies are under the control and '
					+ 'administration of the Company when you visit the Website.',
					'<0>Third-party cookies</0> that are created by other websites that own certain content on the Website '
					+ 'and these cookies are not under the control of the Company.',
				],
			},
			{
				text: '<0>According to the storage time on your end device, cookies are divided into:</0>',
				list: [
					'<0>Temporary</0> which are deleted after the end of your visit to the Website.',
					'<0>Permanent</0> which remain stored even after the end of your visit to the Website.',
				],
			},
			{
				text: '<0>Depending on the function and purpose for which we use cookies, we can divide them into the following:</0>',
				list: [
					'<0>Functional cookies</0>, which we use primarily to ensure that our Website and Application will function properly, to find '
					+ 'out what your preferences are and to make the Website remember that you have logged in, and to monitor users in '
					+ 'order to register them. The necessary cookies are also used to optimize our Website and Application in real time according to how '
					+ 'you use the Website and to load the Website and Application evenly. Without these files, the Website cannot function properly.',
					'<0>Analytical cookies</0>, which are used to analyze the use of the Website, to count visitors, to monitor the time '
					+ 'of traffic and to find out how the Website can be improved. We do not associate Website usage statistics and '
					+ 'other reports with specific people.',
					'<0>Marketing cookies</0>, which are used to collect personal information about the user from a marketing point '
					+ 'of view. E.g.\u00a0collect information in order to adapt the offered advertising to the customer\'s interests, '
					+ 'connection with social networks, transfer of information between affiliate partners,\u00a0etc',
				],
			},
		],
	},
	table: {
		title: 'An overview of specific cookies used on the Website',
		head: [
			'Cookies',
			'Domain or\u00a0tool',
			'First\u00a0party /\u00a0Third\u00a0party',
			'Purpose',
			'Category',
			'Period',
		],
		rows: [
			{
				name: '_ga',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Counts and stores the number of pageviews.',
				category: 'Analytical',
				period: '2\u00a0years',
			},
			{
				name: '_gid',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Counts and stores the number of pageviews.',
				category: 'Analytical',
				period: '1\u00a0day',
			},
			{
				name: '_gat_UA-*',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Limits the amount of data that Google stores on high-traffic sites.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: 'YSC',
				domain: 'youtube.com',
				party: 'Third party',
				purpose: 'Monitors and collects information about user actions on YouTube videos.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: 'PHPSESSID',
				domain: 'api.fondee.cz',
				party: 'First party',
				purpose: 'Provides basic website functionality.',
				category: 'Functional',
				period: '14\u00a0days',
			},
			{
				name: '_nss',
				domain: 'api.fondee.cz',
				party: 'First party',
				purpose: 'Checks what triggered the page to load.',
				category: 'Functional',
				period: 'Session',
			},
			{
				name: '_nss',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Checks what triggered the page to load.',
				category: 'Functional',
				period: 'Session',
			},
			{
				name: 'LaUserDetails',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Stores data to identify users.',
				category: 'Functional',
				period: 'Session',
			},
			{
				name: 'LaSID',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Visitor session identifier.',
				category: 'Functional',
				period: 'Session',
			},
			{
				name: 'LaVisitorId_*',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'The visitor identifier on each page where the chat widget is integrated.',
				category: 'Functional',
				period: 'Session',
			},
			{
				name: 'LaVisitorNew',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Information on whether this is a new or returning visitor.',
				category: 'Functional',
				period: '1\u00a0day',
			},
			{
				name: COOKIE_CONSENT_NAME,
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Stores consent to the processing of cookies.',
				category: 'Functional',
				period: '1\u00a0year',
			},
			{
				name: 'PHPSESSID',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Provides basic website functionality.',
				category: 'Functional',
				period: '30\u00a0days',
			},
			{
				name: 'VISITOR_INFO1_LIVE',
				domain: 'youtube.com',
				party: 'Third party',
				purpose: 'Allows you to estimate the internet speed of the user to play Youtube videos.',
				category: 'Functional',
				period: '6\u00a0months',
			},
			{
				name: 'fondee_infobox',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Tracking whether a user has clicked notifications on the Website.',
				category: 'Functional',
				period: '1\u00a0year',
			},
			{
				name: 'seen_find_out_profile_popup',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Used to track users for registration.',
				category: 'Functional',
				period: 'Permanent',
			},
			{
				name: '_fbp',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Tracks and stores visits across sites.',
				category: 'Marketing',
				period: '3\u00a0months',
			},
			{
				name: '_gcl_au',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Tracks and stores user conversions.',
				category: 'Marketing',
				period: 'Permanent',
			},
			{
				name: 'IDE',
				domain: 'doubleclick.net',
				party: 'Third party',
				purpose: 'User tracking for displaying relevant ads.',
				category: 'Marketing',
				period: '13\u00a0months',
			},
			{
				name: 'test_cookie',
				domain: 'doubleclick.net',
				party: 'Third party',
				purpose: 'User tracking for displaying relevant ads.',
				category: 'Marketing',
				period: '15\u00a0minutes',
			},
			{
				name: 'sid',
				domain: 'seznam.cz',
				party: 'Third party',
				purpose: 'User tracking for displaying relevant ads.',
				category: 'Marketing',
				period: '30\u00a0days',
			},
			{
				name: 'ehubSession_e4d32378',
				domain: 'ehub',
				party: 'Third party',
				purpose: 'Tracking of affiliate program registrations.',
				category: 'Marketing',
				period: '30\u00a0days',
			},
			{
				name: 'ehubVisitorId',
				domain: 'ehub',
				party: 'Third party',
				purpose: 'Tracking of affiliate program registrations.',
				category: 'Marketing',
				period: '30\u00a0days',
			},
			{
				name: '_hjSessionUser{site_id}',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Assigns a unique ID to each site visitor.',
				category: 'Analytical',
				period: '365\u00a0days',
			},
			{
				name: '_hjSession{site_id}',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Saves current data from Session.',
				category: 'Analytical',
				period: '30\u00a0minutes',
			},
			{
				name: '_hjClosedSurveyInvites',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'It is assigned when the user performs an action with a questionnaire window.',
				category: 'Analytical',
				period: '365\u00a0days',
			},
			{
				name: '_hjDonePolls',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'It is assigned when the user completes the questionnaire via a widget on the Website.',
				category: 'Analytical',
				period: '365\u00a0days',
			},
			{
				name: '_hjMinimizedPolls',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'It is assigned when the user minimizes the widget on the Website.',
				category: 'Analytical',
				period: '365\u00a0days',
			},
			{
				name: '_hjShownFeedbackMessage',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Assigned when the user minimizes or completes feedback.',
				category: 'Analytical',
				period: '365\u00a0days',
			},
			{
				name: '_hjSessionTooLarge',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Activates when the data size in the Session is too large.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: '_hjSessionRejected',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'It is assigned when the external server has refused communication due to congestion.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: '_hjSessionResumed',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'It is assigned when the connection to the server has been re-established after a failure.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: '_hjid',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Assigns a unique ID to each site visitor.',
				category: 'Analytical',
				period: '365\u00a0days',
			},
			{
				name: '_hjRecordingLastActivity',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Used to track user actions.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: '_hjTLDTest',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Used when cookies need to be shared between subdomains.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: '_hjUserAttributesHash',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Encrypted attributes of visitors sent via Hotjar API.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: '_hjCachedUserAttributes',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Saved encrypted visitor attributes.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: '_hjLocalStorageTest',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Used to determine if Hotjar Tracking Script can use local storage.',
				category: 'Analytical',
				period: 'Less than 100\u00a0ms',
			},
			{
				name: '_hjIncludedInPageviewSample',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'It is used to determine whether the user is part of the tracking sample according to the page views.',
				category: 'Analytical',
				period: '30\u00a0minutes',
			},
			{
				name: '_hjIncludedInSessionSample',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Used to determine if the user is part of a tracking sample according to the Session length limit.',
				category: 'Analytical',
				period: '30\u00a0minutes',
			},
			{
				name: '_hjAbsoluteSessionInProgress',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: "Identifies the user's first Session by pageviews.",
				category: 'Analytical',
				period: '30\u00a0minutes',
			},
			{
				name: '_hjFirstSeen',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Identifies the first Session of the user.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: '_hjViewportId',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'Stores user viewport information, such as resolution and size.',
				category: 'Analytical',
				period: 'Session',
			},
			{
				name: '_hjRecordingEnabled',
				domain: 'fondee.cz',
				party: 'First party',
				purpose: 'It is used for checking, if the visitor is already part of some monitoring or not.',
				category: 'Analytical',
				period: 'Session',
			},
		],
	},
	options: {
		title: 'Your options for managing cookies',
		text: '<0>When you visit the Website, you will see a\u00a0so-called cookie banner with the option of setting '
			+ 'optional cookies and with the option of accepting or rejecting them. Within the cookie banner you have the '
			+ 'option to choose your preferences and confirm their selection, which you can change at any time below on this page.</0>'
			+ '<0>You can also disable cookies by changing your browser settings, which allows you to refuse the use of '
			+ 'all or only selected cookies. However, we would like to warn you that if you set your browser to turn off all '
			+ 'cookies, including the necessary ones, you may not be able to access the Website and/or their functionality may be limited.</0>'
			+ '<0>Disabling cookies does not delete cookies from the browser and it is necessary to delete them independently '
			+ 'in the browser. You can also delete existing cookies that are already placed in the storage of your terminal device.</0>'
			+ '<0>Through your browser settings, you can allow all cookies, block all cookies or only third-party cookies, '
			+ 'and you can also request notifications regarding stored cookies. More detailed information on cookie settings is '
			+ 'available directly in your browser settings, or please refer to your browser\'s help.</0>',
	},
	management: {
		title: 'Third party cookie management',
		text: '<0>For more detailed information on how third-party cookies are managed and set, we recommend '
			+ 'reading the relevant cookie policy:</0>',
		list: [
			{
				title: 'Youtube',
				link: 'https://www.youtube.com/account_privacy',
			},
			{
				title: 'Hotjar',
				link: 'https://www.hotjar.com/legal/policies/privacy/',
			},
			{
				title: 'Google Analytics',
				link: 'https://www.cookiepolicygenerator.com/cookies-policy-google-analytics/',
			},
			{
				title: 'Live Agent',
				link: 'https://support.liveagent.com/709382-Cookies-used-by-LiveAgent',
			},
			{
				title: 'Facebook',
				link: 'https://www.facebook.com/policy/cookies/',
			},
			{
				title: 'LinkedIn',
				link: 'https://cz.linkedin.com/legal/cookie-policy',
			},
			{
				title: 'Google',
				link: 'https://policies.google.com/technologies/cookies?hl=en',
			},
			{
				title: 'Seznam',
				link: 'https://www.seznam.cz/reklama/cz/obsahovy-web/pravidla-reklamy/gdpr/#data-ktera-jsou-potrebna',
			},
		],
	},
	final: {
		title: 'Final Provisions',
		text: '<0>Information on the processing of personal data is available <0>here</0>.</0>'
			+ '<0>As the world of information technology continues to evolve, we reserve the right to change this Policy at any time.</0>'
			+ '<0>If you have any questions regarding cookies, please contact us at <1>info@fondee.cz</1>.</0>',
	},
	edit: 'Cookie settings',
};

const dashboard = {
	filter: {
		title: 'Total net growth for',
		options: {
			day: 'Day',
			week: 'Week',
			month: 'Month',
			threemonths: '3 months',
			sixmonths: '6 months',
			year: 'Year',
			fromstart: 'From the beginning',
		},
	},
	chart: {
		loadmore: 'Load more',
		loadall: 'Load all',
		toggle: {
			value: 'Value',
			evaluation: 'Net growth',
		},
		tooltip: {
			value: 'Value',
			evaluation: 'Portfolio net growth',
			nonGrowthPlus: 'Deposits/bonuses',
			nonGrowthMinus: 'Withdrawals/transfers between portfolios',
			growthPlus: 'Dividends/rebates',
			growthMinus: 'Fees',
		},
	},
};

const documents = {
	title: 'Documents',
	subtitle: 'Agreements and other documents',
	secondTitle: 'Mandatory information',
	reportsTitle: 'Annual reports',
	esgTitle: 'Information related to\u00a0sustainability',
	otherTitle: 'Other documents',
	contract: 'Signed agreement with Fondee',
	conditions: 'Business terms and conditions',
	terms: 'Webpage usage terms',
	personalInfoAgreement: 'Personal data protection',
	informationSheet: 'Information for clients',
	agreementTemplate: {
		standard: 'Agreement template in\u00a0English',
		child: 'Agreement template in\u00a0English - minors',
		pension: 'Agreement template in\u00a0English - DIP',
	},
	complaintsProcedure: 'Complaints procedure',
	annualReport2021: '2021 Annual report',
	annualReportTemplates2021: '2021 Templates for publishing OCP information',
	annualReport2022: '2022 Annual report',
	annualReportTemplates2022: '2022 Templates for publishing OCP information',
	annualReport2023: '2023 Annual report',
	annualReportTemplates2023: '2023 Templates for publishing OCP information',
	esgInfolist: 'ESG infolist',
	esgSustainability: 'Statement on\u00a0sustainability policies and disregarding adverse impacts of\u00a0investment decisions',
	legalRepresentativeDeclaration: 'Legal representative declaration',
	pensionConfirmation: 'Confirmation for the employer',
	tradingVenues: 'List of trading venues',
	ordersExecution: 'Summary and conclusions of the\u00a0quality of execution of orders for 2023',
};

const documentUrls = {
	conditions: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_Commercial_terms.pdf`,
	terms: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_Website_Terms_of_use.pdf`,
	personalInfoAgreement: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_GDPR.pdf`,
	informationSheet: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_Information_for_clients.pdf`,
	agreementTemplate: {
		standard: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_contract.pdf`,
		child: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_children_contract.pdf`,
		pension: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_pension_contract.pdf`,
	},
	complaintsProcedure: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_Claims_policy.pdf`,
	annualReport2021: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2021.pdf`,
	annualReportTemplates2021: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2021.xlsx`,
	annualReport2022: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2022.pdf`,
	annualReportTemplates2022: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2022.xlsx`,
	annualReport2023: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2023.pdf`,
	annualReportTemplates2023: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2023.xlsx`,
	esgInfolist: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_ESG_infolist.pdf`,
	esgSustainability: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_Statement_on_disregarding.pdf`,
	feesIllustratedUrl: `${process.env.PUBLIC_URL}/files/en/Fondee_Illustrative_Impact_of_Fees.pdf`,
	tradingVenues: `${process.env.PUBLIC_URL}/files/Seznam_prevodnich_mist.pdf`,
	ordersExecution: `${process.env.PUBLIC_URL}/files/Fondee_zavery_analyzy_provadeni_pokynu_2023.pdf`,
	priceList: `${process.env.PUBLIC_URL}/files/en/EN_Fondee_pricelist.pdf`,
};

const email = {
	address: 'info@fondee.cz',
};

const emailUs = {
	title: 'Write to',
};

const emailVerification = {
	default: {
		title: 'E-mail address confirmation',
		text: 'We have sent a confirmation e-mail to the e-mail address. Please continue registration via '
			+ 'the link provided in the e-mail. It is also for your safety to make sure that the address entered is '
			+ 'really yours.',
		listTitle: 'Still haven\'t received the e-mail?',
		list: '<0>check your spam folder</0>'
			+ '<0>is your e-mail address correct?</0>'
			+ '<0>try registering again</0>'
			+ '<0>nothing worked? Get in touch at <0>info@fondee.cz</0> or using the chat feature.</0>',
	},
	response: {
		button: 'Continue with registration',
		title: {
			error: 'E-mail address was not verified.',
			success: 'E-mail address was successfully verified.',
		},
		content: {
			error: 'E-mail address verification failed. Try updating the page and\u00a0in\u00a0case of persistent problems contact us at <0>$t(email.address)</0>.',
			success: 'Thank you for verifying. You can now continue registering.',
		},
	},
};

const employer = {
	create: {
		open: 'Add employer',
		title: 'Add employer',
		text: 'To find an\u00a0employer, you need to enter a\u00a0valid business ID (IČO), which you can find, '
			+ 'for example, in your contract with your employer.',
		disabled: 'You cannot add another employer. Please contact customer support at info@fondee.cz.',
		form: {
			label: 'Business ID (IČO)',
			placeholder: 'Fill in a\u00a0valid business ID (IČO)',
			submit: 'Verify',
			found: 'Company found by business ID',
			name: 'Company name:',
			address: 'Address:',
			confirm: 'Confirm provided information',
			aresError: 'The\u00a0business ID (IČO) could not be verified. Please contact us at info@fondee.cz.',
			employerExistsError: 'The\u00a0employer is already in your list.',
			invalidBusinessId: 'Invalid business ID (IČO), please try again',
		},
	},
	list: {
		info: 'If your employer contributes or will contribute to your pension portfolio under the DIP, please '
			+ 'add the\u00a0details below. If you have changed jobs or your employer will no longer be contributing, '
			+ 'you can remove the\u00a0information you have already entered.',
		tooltip: {
			title: 'Valid',
			text: 'If the entry is valid, the\u00a0employer will contribute to the\u00a0pension portfolio. '
				+ 'If you no longer work for that employer, the\u00a0employer no longer contributes, '
				+ 'or the information has changed, mark the\u00a0entry as invalid.',
		},
	},
};

const error = {
	title: 'Something went wrong',
	subtitle: 'We are sorry, there was an error while loading your page.',
	link: 'Back to homepage',
};

const error404 = {
	title: '404',
	subtitle: 'We apologize, but the requested page was not found.',
	link: 'Back to homepage',
};

const faq = {
	title: 'How and when the investment takes place',
	search: 'Find a question…',
};

const feesChart = {
	comparisonAnchor: 'Comparison of fees',
	title: 'How do we compare<0></0> with an\u00a0average mutual fund?',
	subtitle: 'Compared to a mutual fund, Fondee can save you up to tens of thousands of CZK',
	info: '<0>For example, with an\u00a0initial investment of CZK 100k you can <0>save over CZK 50k during next 15\u00a0years</0></0>'
		+ '<0>Assuming the average Fondee fee of <0>1.09% p.a.</0>, the mutual fund\'s fee <0>2.7%</0> and '
		+ 'the\u00a0average annual stock portfolio\'s return <0>7%</0>, then the difference after 15\u00a0years in '
		+ 'Fondee is CZK\u00a051\u00a0700.</0>'
		+ '<0>If you are interested in\u00a0a\u00a0more detailed impact of fees on\u00a0returns, '
		+ 'you can take a\u00a0look <1>here</1>.</0>',
	chart: {
		currencySuffix: '\u00a0CZK',
		currencyThousandsSuffix: 'k\u00a0CZK',
		years_one: '{{ count }} year',
		years_other: '{{ count }} years',
		curveLabel: 'mutual funds',
	},
};

const feesComparison = {
	title: 'Fee comparison for a stock-based portfolio',
	footerNote: '\u00b9 There is no withdrawal fee after 5\u00a0years of investing.',
	footerTextNote1: 'Comparison for January 2025',
	footerTextNote2: 'The values come from the Key Information for Investors, available at the websites of the '
		+ 'individual funds. The entry and exit fees may be lower or none, if the given distributor\'s current price list '
		+ 'is lower than the fees listed in the Key Information for Investors.',
	footerTextNotePart2: 'Specifically, the\u00a0average annual costs are calculated as following: total annual expenses for the\u00a0last year '
		+ 'according to the\u00a0Key Information for Investors +\u00a0(entry +\u00a0withdrawal fee applicable '
		+ 'for the\u00a0selected period) /\u00a0number of years of investing.',
	footerTextNote3: 'The fee is calculated from the value of 100,000 CZK and it is based on the assumption that '
		+ 'the client\'s portfolio keeps this value throughout the whole investment period. In reality, this value '
		+ 'may increase or decrease, based on which the fees will proportionally increase or decrease.',
	footerTextNotePart3: 'Specifically, the\u00a0total costs of managed funds are calculated as '
		+ 'following: 100,000 CZK *\u00a0average annual costs *\u00a0number of years of investing.',
	footerTextNote4: 'Do you also feel like the amount of * is getting a little absurd? This is usually the case with '
		+ 'managed funds. Always read the full terms and conditions of the service!',
};

const feesHero = {
	title: 'Fees? Lower than you\'d expect…',
	description: '…and than you know from elsewhere. That\'s more money for you! With Fondee, you know exactly how much you\'re paying.',
	info: 'The annual fees charged by ETFs vary according to the composition of the portfolio. They are on average 0.15%',
	anchor: 'Comparison with mutual fund',
	period: 'per\u00a0annum',
	list: [
		{
			title: 'Fondee fees',
			value: '0.9%',
		},
		{
			title: 'ETF fees on\u00a0average',
			value: '0.15%',
		},
		{
			title: 'Total average fees',
			value: '1.05%',
		},
	],
	benefitsList: [
		'No entry fees',
		'No hidden fees',
		'No banker fees',
		'No fees to financial advisors',
	],
};

const feesProducts = {
	title: 'How much does investing with Fondee cost?',
	toggleOptions: [
		{
			label: 'Classic',
			value: 'classic',
		},
		{
			label: 'ESG',
			value: 'esg',
		},
		{
			label: 'Children´s',
			value: 'child',
		},
		{
			label: 'Pensioners',
			value: 'pension',
		},
		{
			label: 'Student',
			value: 'student',
		},
	],
	products: {
		child: {
			title: 'Children\'s accounts',
			text: 'How to create a\u00a0richer future for your children? Don\'t let your savings sit in the\u00a0bank where they lose '
				+ 'value. Open a\u00a0reduced-fee children\'s investment account.',
			button: 'I want a\u00a0children\'s account',
			transparent: {
				fondee: '0.2%',
				vat: '0.04%',
				total: '0.24%',
				description: '<0>Fondee fees <0>0.2%</0> per annum</0>'
					+ '<1>We have reduced investment costs for children´s accounts to a minimum. The fee includes complete '
					+ 'management of the children\'s portfolio.</1>'
					+ '<0>VAT 0.04% per year</0>'
					+ '<1>According to the law, our activity falls under financial services subject to VAT.</1>'
					+ '<1>The fee is rounded; the exact rate can be found in\u00a0the\u00a0<0>price list</0>.</1>',
			},
		},
		classic: {
			title: 'Tailor-made investing',
			text: 'Invest in <0>hundreds of stocks and bonds from around the world</0> through ETFs\u00a0- exchange-traded funds. '
				+ 'Simple, automated and easy to understand.',
			button: 'I want a classic portfolio',
			transparent: {
				fondee: '0.9%',
				vat: '0.19%',
				total: '1.09%',
				description: '<0>Fondee fees <0>0.9%</0> per annum</0>'
					+ '<1>No surprises with Fondee. We only charge one fee that includes the complete management of your '
					+ 'investments. Invest cheaper than through mutual funds!</1>'
					+ '<0>VAT 0.19% per year</0>'
					+ '<1>According to the law, our activity falls under financial services subject to VAT.</1>'
					+ '<1>The fee is rounded; the exact rate can be found in the <0>price list</0>.</1>',
			},
		},
		esg: {
			title: 'ESG',
			text: 'Invest sustainably with ESG portfolios, which have beaten traditional investments in returns in recent years. '
				+ 'You\'ll see that <0>values and returns go hand in hand</0>.',
			button: 'I want an\u00a0ESG portfolio',
			transparent: {
				fondee: '0.9%',
				vat: '0.19%',
				total: '1.09%',
				description: '<0>Fondee fees <0>0.9%</0> per annum</0>'
					+ '<1>No surprises with Fondee. We only charge one fee that includes the complete management of your '
					+ 'investments. Invest cheaper than through mutual funds!</1>'
					+ '<0>VAT 0.19% per year</0>'
					+ '<1>According to the law, our activity falls under financial services subject to VAT.</1>'
					+ '<1>The fee is rounded; the exact rate can be found in the <0>price list</0>.</1>',
			},
		},
		pension: {
			title: 'Pension investment account (DIP)',
			text: 'Secure a\u00a0richer retirement with smart, low-fee investments. Do not rely on the government and say goodbye to expensive funds.',
			button: 'I want a\u00a0pension portfolio',
			transparent: {
				fondee: '0.5%',
				vat: '0.11%',
				total: '0.61%',
				description: '<0>Fondee fee 0.5% per annum</0>'
					+ '<1>Investing for retirement cheaper than at a\u00a0bank? You got it! The lower fee includes complete management of the pension portfolio.</1>'
					+ '<0>VAT 0.11% per year</0>'
					+ '<1>According to the law, our activity falls under financial services subject to VAT.</1>'
					+ '<1>The fee is rounded; the exact rate can be found in the <0>price list</0>.</1>',
			},
		},
		student: {
			title: 'Student accounts',
			text: 'Use your time to your advantage! Invest while you\'re still in school with a\u00a0Fondee student account '
				+ '<0>with a\u00a0reduced fee</0>. All you need is a\u00a0study certificate or ISIC and it\'s yours until you\'re 26.',
			button: 'I want a student account',
			transparent: {
				fondee: '0.5%',
				vat: '0.11%',
				total: '0.61%',
				description: '<0>Fondee fees 0.5% per annum</0>'
					+ '<1>With one certificate of study or ISIC card you can invest with a\u00a0lower fee than '
					+ 'in\u00a0"adult" portfolios until your 26th birthday.</1>'
					+ '<0>VAT 0.11% per year</0>'
					+ '<1>According to the law, our activity falls under financial services subject to VAT.</1>'
					+ '<1>The fee is rounded; the exact rate can be found in the <0>price list</0>.</1>',
			},
		},
	},
	transparent: {
		title: 'Transparent fees:',
		fondee: 'Fondee fees',
		vat: 'VAT',
		total: 'Total costs / fees',
	},
};

const findOutInfoBox = {
	text: 'Find out which investor profile best suits your risk attitude. '
		+ 'The assessment takes place through an investment questionnaire in the first step of the registration.',
	button: 'I want to try it',
};

const footer = {
	primary: {
		nav: {
			title: 'Information',
			homepage: 'Introduction',
			howItWorks: 'How it works',
			ourApproach: 'Our approach',
			fees: 'Fees / Pricing',
			faq: 'FAQ',
			contact: 'Ask us',
			documents: 'Documents',
			cookies: 'Cookies',
			blog: 'Blog',
			privacyPolicy: 'Privacy policy',
		},
		products: {
			title: 'Products',
			classic: 'Classic portfolio',
			esg: 'ESG portfolios',
			children: 'Children\'s accounts',
			pension: 'Pension investment account',
			student: 'Student accounts',
		},
		contact: {
			title: 'Do you have a question?',
		},
		app: 'Download the mobile app',
		googlePlay: 'Now on Google Play',
		appStore: 'Download from the\u00a0App Store',
		socialUrl: {
			facebook: 'https://www.facebook.com/FondeeCZ',
			instagram: 'https://www.instagram.com/fondee_cz/',
			linkedin: 'https://www.linkedin.com/company/fondeecz/',
			twitter: 'https://twitter.com/fondee_cz',
		},
	},
	secondary: {
		title: 'Fondee\u00a0a.s.',
		info: 'The value of the investment may fluctuate over time, the return on the invested amount is not guaranteed. '
			+ 'There are risks associated with investments. The investor is mainly exposed to market risks consisting in the '
			+ 'possibility of unfavorable development of asset prices (shares, bonds, etc.), credit risks, inflation risk '
			+ 'and exchange rate risks.',
		address: 'ID No.\u00a006691862<0></0>'
			+ 'Rumunská\u00a022/28,<0></0>'
			+ 'Vinohrady\u00a0-\u00a0Praha\u00a02, 120\u00a000',
		legal: 'Fondee\u00a0a.s. is registered in the\u00a0Commercial Register kept by\u00a0the Municipal Court '
			+ 'in\u00a0Prague, file number B\u00a027322',
		love: 'Made with\u00a0<0></0>\u00a0Fondee',
	},
};

const footerCta = {
	title: 'Future? Make your own!',
	text: 'Make your money work for you.',
	button: 'Start investing',
};

const forgotPassword = {
	title: 'Recover forgotten password',
	form: {
		text: 'After entering your e-mail address, we will send you a link to reset your password.',
		button: 'Send a link',
		success: 'A link to reset the old password has just been sent to your email address.',
	},
	reset: 'Change password',
};

const forms = {
	error: 'Something went wrong. Please try again.',
	success: 'Saved',
	save: 'Save',
	fields: {
		email: {
			label: 'E-mail',
			helper: 'We promise not to send useless spam',
			error: 'You forgot to fill in your e-mail address.',
			invalid: 'The e-mail address you\'ve provided is invalid. Please make sure you haven\'t made a typo, forgotten the @, etc.',
			unique: 'This e-mail address is already registered in our database. Please use a different e-mail address.',
			closed: 'Your account has been closed. If you would like to renew your account, please contact <0>$t(email.address)</0>.',
		},
		esgPreferenceDeclaration: {
			error: 'Please tick this box to continue',
		},
		password: {
			label: 'Password',
			helper: 'At least 8 characters, one capital, one lower case letter, and a number',
			error: 'You forgot to fill in your password.',
			incorrect: 'Sorry, this password is incorrect.',
			invalid: 'This password doesn\'t meet the required conditions.',
		},
		passwordConfirm: {
			label: 'Confirm the password',
			error: 'You forgot to confirm the password.',
			invalid: 'The passwords don\'t match.',
		},
		oldPassword: {
			label: 'Old password',
			error: 'You forgot to fill in the old password.',
		},
		newPassword: {
			label: 'New password',
			error: 'You forgot to fill in the new password.',
		},
		firstName: {
			label: 'Name',
			error: 'Please fill in your first name.',
			warning: 'Please make sure that the name you\'ve typed in is correct. If it is, continue.',
		},
		lastName: {
			label: 'Surname',
			error: 'Please fill in your surname.',
			warning: 'Please make sure that the name you\'ve typed in is correct. If it is, continue.',
		},
		fullName: {
			label: 'Full name',
			error: 'Please fill in your full name.',
		},
		phone: {
			label: 'Phone number',
			error: 'Please don\'t forget to fill in your phone number.',
			invalid: 'You\'ve used an invalid format of the phone number. The number should have 9\u00a0digits.',
		},
		phoneNumber: {
			label: 'Phone number',
			helper: 'No spaces, please',
			error: 'You forgot to fill in the phone number.',
			invalid: 'The phone number you\'ve used is invalid. Please make sure you\'ve typed it correctly, with no spaces.',
			warning: 'Please make sure that the number you\'ve used is correct.',
		},
		hasReferenceCode: {
			label: 'I have a reference code',
		},
		referenceCode: {
			label: 'Reference code',
			error: 'You forgot to fill in your reference code.',
			invalid: 'We didn\'t find this reference code in our database. Please make sure you\'ve typed the reference code correctly.',
		},
		personalInfoAgreed: {
			label: 'I agree with the processing of <0>personal data</0>',
			error: 'To continue, you need to confirm that you agree with the processing of personal data.',
		},
		conditionsAgreed: {
			label: 'I agree with the <0>Business Terms and Conditions</0> of Fondee',
			error: 'To continue, you need to confirm that you are familiar with the Fondee business terms.',
		},
		streetName: {
			label: 'Street',
			error: 'You forgot to fill out the street.',
			warning: 'Please check that the street is correct.',
		},
		streetNumber: {
			label: 'Number',
			error: 'You forgot to fill out the building number.',
			warning: 'Check that the house number is correct.',
		},
		city: {
			label: 'Town',
			error: 'You forgot to fill out the city.',
		},
		postalCode: {
			label: 'Post code',
			nonNumeric: 'Invalid format. Use numbers only.',
		},
		personalIdentificationNumber: {
			label: 'Personal identification number',
			error: 'Invalid format. Use numbers only, no slash.',
			warning: 'Please make sure that the personal identification number you\'ve typed in is correct.',
		},
		birthDate: {
			label: 'Date of birth',
			nonNumeric: 'Wrong format. Enter digits only.',
			minority: 'The child was already 18, it is necessary to create a separate registration',
			majority: 'You are not yet 18, a legal representative must complete the registration for you',
			day: {
				label: 'Day',
				invalid: 'Enter the correct day number.',
			},
			month: {
				label: 'Month',
				invalid: 'Enter the correct month number.',
			},
			year: {
				label: 'Year',
				invalid: 'Enter the correct year number.',
			},
		},
		placeOfBirth: {
			label: 'Place of birth',
			error: 'Invalid format. Use letters and numbers only.',
			warning: 'Please make sure that the place of birth you\'ve typed in is correct.',
		},
		bankAccount: {
			label: 'Bank account',
			labelPrefix: 'Prefix',
			labelAccount: 'Account number',
			labelCode: 'Bank code',
			errorAccount: 'You forgot to fill in the account number.',
			errorCode: 'You forgot to fill in the bank code.',
			invalidCode: 'Invalid bank code.',
			length: 'Please check that your account number is 26 characters long.',
			nonNumeric: 'Invalid format. Use numbers only.',
		},
		bankAccountIBAN: {
			label: 'IBAN',
			error: 'You forgot to fill in the IBAN account number.',
			invalid: 'The IBAN of the account may contain only numbers and letters. It should not exceed 34 characters in length.',
			warning: 'Please check that your IBAN account number is 24 characters long.',
		},
		statementPeriodicity: {
			monthly: 'Monthly',
			quarterly: 'Quarterly',
			yearly: 'Annually',
			none: 'Never',
		},
		survey: {
			label: 'Display surveys in the client zone',
		},
		marketingEmailsUnsubscribe: {
			label: 'Unsubscribe from receiving marketing e-mails',
		},
		nationality: {
			label: 'Nationality',
			error: 'You forgot to fill in your nationality.',
		},
		rebalancingActive: {
			label: 'Activate automatic portfolio rebalancing ',
		},
		toggle: {
			local: 'Local bank no.',
			iban: 'IBAN',
		},
		address: {
			change: 'Address of permanent residence',
		},
		contactAddress: {
			label: 'Contact address',
		},
		addressChange: {
			change: 'I wish to change my permanent address',
			confirm: 'I declare that the address I have provided is correct',
		},
		businessId: {
			warning: 'The ID number you\'ve used is invalid.',
		},
		incomeExpense: {
			warning: 'Please check that the amount is correct. The amount must be indicated as a\u000a0whole number in\u000a0CZK (not in\u000a0thousands etc.).',
		},
		numberISIC: {
			format: 'Invalid format.',
		},
		child: {
			firstName: {
				label: 'Child\'s name',
				error: 'Please fill in the child\'s name.',
				warning: 'Please check that the entered name is correct.',
			},
			lastName: {
				label: 'Child\'s surname',
				error: 'Please fill in the child\'s surname.',
				warning: 'Please check that the entered surname is correct.',
			},
			email: {
				label: 'E-mail (optional)',
				unique: 'This e-mail is already registered with another user. If the child does not have his/her own e-mail, you can leave the field empty.',
				warning: 'Please check that the entered e-mail is correct.',
			},
			phone: {
				label: 'Phone (optional)',
				warning: 'Please check that the entered phone number is correct.',
			},
			referenceCode: {
				label: 'Reference code (optional)',
				invalid: 'We didn\'t find this reference code in our database. Please make sure you\'ve typed the reference code correctly.',
				tooltip: 'Please note that only one-time cash bonus codes can be entered. Fee waiver bonuses are always governed by the parent\'s account and cannot be entered here.',
			},
		},
		file: {
			deleteAttachment: 'Delete attachment',
			sizeError: 'The maximum file size is {{ value }} MB.',
		},

	},
	twoFactorAuthentication: {
		title: 'Two-factor authentication',
		text: 'Enter the verification code from your application',
		button: 'Enter code',
		error: 'Wrong code',
	},
};

const general = {
	copied: 'Copied',
	esgWarningModal: {
		title: 'Warning',
		text: 'These ESG portfolios may not match your preferences.',
		button: 'I acknowledge',
	},
};

const header = {
	blog: 'Blog',
	clientCheck: 'Client check',
	contact: 'Ask us',
	dashboard: 'Dashboard',
	fees: 'Pricing',
	history: 'History',
	howItWorks: 'How it works',
	login: 'Sign in',
	logout: 'Sign out',
	menu: 'Menu',
	myAccount: 'My account',
	offers: 'Something extra',
	onboarding: 'Onboarding',
	ourApproach: 'About us',
	ownPortfolio: 'My portfolio',
	portfolio: 'My portfolio',
	products: 'Products',
	productsList: {
		classicProduct: {
			title: 'Tailor-made investing',
			subTitle: 'Suitable for any investor',
		},
		esgProduct: {
			title: 'Sustainable investments',
			subTitle: 'Nature comes first',
		},
		childProduct: {
			title: 'Children\'s investment accounts',
			subTitle: 'Thinking about my children\'s future',
		},
		pensionProduct: {
			title: 'Investing for retirement (DIP)',
			subTitle: 'Take your pension into your own',
		},
		studentProduct: {
			title: 'Investing for students',
			subTitle: 'Start while you\'re still in school',
		},
		button: 'Back',
	},
	referrals: 'References',
	register: 'Start investing',
	registrationCompletion: 'Registration completion',
	signAgreement: 'Sign agreement',
	signNewAgreement: 'Sign agreement',
	taxes: 'Taxes 2023',
};

const historyChart = {
	loading: 'Loading webpage data',
	legend: {
		title: 'Legend',
		evaluation: 'Positive net growth',
		devaluation: 'Negative net growth',
		value: 'Total value of the portfolio',
		increase: 'Deposit / Bonus / Dividend',
		decrease: 'Withdrawal / Fee',
	},
	tooltip: {
		value: 'Value',
		evaluation: 'Portfolio net growth',
		nonGrowthPlus: 'Deposit/bonus',
		nonGrowthMinus: 'Withdrawal/transfers between portfolios',
		growthPlus: 'Dividend/refund',
		growthMinus: 'Fee',
	},
	toggle: {
		value: 'Value',
		evaluation: 'Net growth',
	},
};

const historyFilter = {
	aggregation: 'After',
	fromDate: 'From',
	toDate: 'To',
	last: 'Last ',
	days: 'days',
	beginning: 'Beginning',
	collapse: 'Change the data range',
};

const historyOverview = {
	deposits: {
		title: 'Deposits',
		tooltip: 'The sum of all deposits and any\u00a0possible bonuses for the selected\u00a0period.',
	},
	withdrawals: {
		title: 'Withdrawals',
		tooltip: 'The sum of all withdrawals for the selected\u00a0period.',
	},
	appreciation: {
		title: 'Appreciation',
		tooltip: 'The development of the portfolio\'s value during the selected period, excluding\u00a0all deposits, withdrawals, and\u00a0bonuses.',
	},
};

const historyProgress = {
	title: 'Development of the portfolio\'s value',
	downloadPdf: 'Download in\u00a0PDF',
	noResult: 'No results to\u00a0show',
	table: {
		columns: {
			date: 'Date',
			externalTransactionTotal: 'Deposit/withdrawal/bonus',
			internalTransactionTotal: 'Bonus/fee/dividend',
			value: 'Portfolio value',
			valueTooltip: 'The portfolio\'s value is the total value of the products held and the\u00a0cash converted to the selected '
				+ 'currency based on the exchange rate applicable at the\u00a0given moment. The value of an ETF is calculated '
				+ 'based on ETF prices applicable at the end of the given trading day, or, as the case may be, the nearest available price.',
			valueProgress: 'Portfolio value progress since the previous day',
			valueProgressTooltip: 'The portfolio value progress expresses the changes in value of the purchased products '
				+ 'and\u00a0changes in the exchange rates of currencies. The products are traded in\u00a0EUR, exchange rate fluctuations can therefore '
				+ 'affect their current value. The portfolio value progress is calculated as the difference from the portfolio\'s value '
				+ 'on the previous day, excluding all deposits, withdrawals, bonuses and\u00a0fees.',
		},
		rows: {
			employerDeposit: 'Deposit from employer',
			for: 'for',
			rate: 'Exchange rate:',
			transfer: 'Transfer',
			fromPortfolio: 'from portfolio',
			toPortfolio: 'to portfolio',
			investorProfile: 'Investor profile:',
			skipRebalancing: 'Rebalancing deactivated',
			cashOver: 'Cash ratio adjusted to',
		},
		loadMore: 'Show more movements',
	},
};

const historyTransactions = {
	title: 'Transaction history',
	downloadPdf: 'Download in\u00a0PDF',
	text: 'Transaction\u00a0view details',
	textTooltip: 'The transaction date corresponds with the date of the\u00a0book-entry by the broker.',
	noResult: 'No transactions to\u00a0show',
	filter: {
		placeHolder: 'Transaction filter',
		noOption: 'No filters',
	},
	table: {
		columns: {
			date: 'Date',
			type: 'Type of change',
			change: 'Change',
		},
		rows: {
			more: 'More',
			hide: 'Hide',
			employerDeposit: 'Deposit from employer',
			trading: 'Trading',
			fromEmployer: 'From',
			for: 'for',
			rate: 'Exchange rate:',
			fromPortfolio: 'from portfolio',
			toPortfolio: 'to portfolio',
		},
		loadMore: 'Show more movements',
	},
};

const homepageAbout = {
	title: 'What is Fondee?',
	text: 'Would you like to invest your free money in stocks and bonds? It\'s super easy! All you have to do is to choose '
		+ 'one out of 7\u00a0model portfolios that combine thousands of traded stocks and bonds. '
		+ 'Then you send the money and Fondee will take care of the rest. All orders and maintenance of the\u00a0portfolio '
		+ 'will be taken care of.',
	button: 'Learn more',
};

const homepageHero = {
	title: 'Saving up for the future makes no sense.',
	subtitle: 'Start investing!',
	text: 'Fill out the investment questionnaire and Fondee will design a customized portfolio for you. Invest '
		+ 'automatically <0>starting from CZK\u00a01,000</0>. With low fees and completely online.',
	aboutButton: 'Find out more',
	productsButton: 'Our products',
	future: {
		title: 'Nobody knows the\u00a0future.',
		subtitle: 'Yet everybody can secure theirs.',
		text: 'Put your money to work. Simply, transparently and with low fees.',
	},
};

const homepageJT = {
	text: '<0>We are backed by J&T Bank. It supports Fondee financially and by sharing industry experience. Thank you '
		+ 'for your trust!</0>'
		+ '<0>With a strong financial group behind us, we can keep improving the investment platform and work on new '
		+ 'features for our investors.</0>',
};

const homepageProducts = {
	title: 'How to grow your money?',
	button: 'More information',
	list: [
		{
			title: 'Tailor-made investing',
			text: '<0>Saving up will not secure your future. Invest instead! With just one bank order, you can invest <1>in hundreds '
				+ 'of shares and bonds from all over the world</1> - one-time or regularly and as from as little as CZK\u00a01000.</0>'
				+ '<0>No unnecessary fees or complicated conditions. We will design an ETF portfolio based on your goals and '
				+ 'risk profile. We will <1>automatically invest</1> the deposits, so you don\'t have to worry about anything.</0>',
			button: 'I want a\u00a0classic portfolio',
		},
		{
			title: 'Sustainable investments',
			text: '<0>Invest responsibly and open a sustainable portfolio composed of ETFs <1>that excel in ESG rating</1>. '
				+ 'This rating assesses the impact of the investment on the environment (E), society (S) and the quality of management (G).</0>'
				+ '<0>Choose from <1>7 ESG portfolios</1>, which have beaten traditional investments in returns in recent years. '
				+ 'You\'ll see that <1>values and returns go hand in hand</1>.</0>',
			button: 'I want an\u00a0ESG portfolio',
		},
		{
			title: 'Investing for children',
			text: '<0>How to financially secure the little ones? It´s good you ask, <1>they\'ll thank you in the\u00a0future</1>! '
				+ 'A\u00a0few hundred in investments for children now = a\u00a0nice package in adulthood.</0>'
				+ '<0>Get your kids a\u00a0<1>reduced-fee</1> investment account.</0>',
			button: 'I want a\u00a0children´s account',
		},
		{
			title: 'Pension investment account (DIP)',
			text: '<0>Take the future into <1>your own hands</1>! Do not rely on the government and say goodbye to expensive pension funds and pension insurance with poor returns.</0>'
				+ '<0>Create a pension portfolio and save for your retirement simply, <1>with employer contributions, tax benefits and a lower fee</1> compared to our regular portfolios.</0>',
			button: 'I want a\u00a0pension portfolio',
		},
		{
			title: 'Investing for students',
			text: '<0>Possibility of contributions from loved ones, <1>discounted fees</1>, no minimum deposit and '
				+ 'a\u00a0100\u00a0CZK bonus for ISIC –\u00a0that\'s the\u00a0student account from Fondee. Once you prove that you '
				+ 'are in\u00a0school, its benefits are yours <1>until the age of 26</1>.</0>'
				+ '<0>It\'s never too early to invest and every penny counts. Start while you\'re in school and <1>grow your '
				+ 'money in\u00a0ETF portfolios</1>.</0>',
			button: 'I want a\u00a0student account',
		},
	],
};

const homepageServices = {
	list: [
		{
			title: 'Simply',
			text: 'You can open an account <0>online in just a\u00a0few clicks</0>, so you can start growing your money in just a\u00a0'
				+ 'few minutes. You can see your portfolio performance on our website at any time.',
		},
		{
			title: 'Smart',
			text: 'A short questionnaire will tell you what kind of investor you are - both the <0>catious and the fearless</0> will '
				+ 'find their place here. Do you have multiple financial goals? Open up to 5\u00a0portfolios at the same time!',
		},
		{
			title: 'Secure',
			text: 'While we take care of your money, we are under supervision of the <0>Czech National Bank</0> and your funds '
				+ 'are protected by the Investor Compensation Fund.',
		},
		{
			title: 'No bullshit*',
			text: 'Invest <0>as much as you want to</0>. You can say goodbye to us at any time - online - and you will have '
				+ 'your money in your bank account within a\u00a0week. We will not list asterisks or exceptions.'
				+ '*Really!',
		},
	],
	button: 'Learn more',
};

const howItWorksHero = {
	title: 'How it works',
	description: '5 simple steps to investing',
	list: [
		{
			name: 'Fill in our online questionnaire',
			description: 'We are Fondee, who are you? Thanks to a <0>short questionnaire</0>, we will get to know your investment '
				+ 'goals and risk profile.',
		},
		{
			name: 'Choose an investment portfolio',
			description: 'Don\'t worry, you won\'t be alone. We will suggest <0>the\u00a0appropriate type of investment strategy</0> for you '
				+ 'according to your answers in the\u00a0introductory questionnaire.',
		},
		{
			name: 'Send your initial payment',
			description: 'All that remains to complete the registration is to send the initial payment. Invest <0>as little as '
				+ 'CZK\u00a01,000</0> one-time or regularly.',
		},
		{
			name: 'Enjoy investing without worries',
			description: 'Fondee <0>buys <0>ETFs</0></0> and takes care of your portfolio to be in line with your chosen profile.',
		},
		{
			name: 'Withdraw your money',
			description: 'You can sell your investment at any time and have <0>the money in your account within a\u00a0week</0>. '
				+ 'Exit fees? There are none.',
		},
	],
	anchor: 'View portfolios',
};

const howItWorksInvestment = {
	title: 'Investing with\u00a0peace of\u00a0mind',
	list: [
		{
			title: 'Secure',
			description: 'The clients\' money and investments are kept on a special <0>client account</0> with the\u00a0broker. '
				+ 'This account is separate from Fondee\'s and/or the\u00a0broker\'s assets. If anything ever happened to Fondee or our '
				+ 'broker, your assets are safe and you have an\u00a0<0>easy access</0> to them.',
		},
		{
			title: 'Supervised',
			description: 'Your assets are protected by the\u00a0<0>Investor Compensation Fund</0>, which guarantees compensation '
				+ 'for <0>as much as 90% of the\u00a0volume</0> (the maximum being 20,000\u00a0EUR).',
		},
		{
			title: 'Professional',
			description: 'The portfolios are taken care of by a\u00a0<1>team of experts</1> with many years of experience gained by '
				+ 'studying at economic universities and working in financial institutions around the world. '
				+ 'Well, <0>you are in good hands</0>.',
		},
	],
};

const howItWorksPrinciples = {
	title: '4\u00a0principles of a\u00a0smart investor',
	list: [
		{
			title: 'Investing is like a roller coaster',
			text: 'One day you\'re up, the next you\'re down. Investing involves risks and returns cannot be guaranteed. '
				+ 'Fondee is not suitable for a\u00a0short-term speculation. We invest with the goal of growth and believe that the\u00a0'
				+ '<0>value of the\u00a0investment will grow in the\u00a0long term</0>. But it may also fall due to a slump in the\u00a0stock and '
				+ 'bond markets. Invest only if you are <0>willing to bear the\u00a0risk of loss</0>.',
		},
		{
			title: 'Yesterday is not today.',
			text: 'And historical returns are no guarantee of future returns. Our investment portfolios have experienced growth in the past, '
				+ 'but there is <0>no guarantee that in the\u00a0future will happen the same</0>. The\u00a0yield can be '
				+ 'higher or lower. We don\'t have, as nobody else does, a\u00a0crystal ball - and when someone promises you a\u00a0certain '
				+ 'profit, they are just pulling your leg.',
		},
		{
			title: 'The lower the fees, the more money for you',
			text: 'Don\'t forget to always check the fees, <0>they eat away the yield of your investments</0>. Even a\u00a0small '
				+ 'difference means a\u00a0lot in the long run. Fondee works automatically online without branches and advisors or '
				+ 'bankers. We save on costs and because of that, we offer you <0>some of the lowest fees on the market</0>.',
		},
		{
			title: 'Currency matters',
			text: 'The <0>development of currencies</0> also affects the growth of your investment. Most of the ETFs from '
				+ 'our portfolios are traded in euros, some also contain dollar items. <0>Exchange rates are constantly changing and '
				+ 'so is the value of your portfolio</0> - therefore investments in Fondee can both rise and fall due to '
				+ 'the currency rate movement.',
		},
	],
};

const howItWorksRebalancing = {
	title: 'What is portfolio rebalancing?',
	description: '<0>Each Fondee portfolio has a\u00a0given percentage of stocks and bonds. Stock and bond prices change every '
		+ 'day, and therefore, over time, the composition of your portfolio <0>may be different from the composition you have '
		+ 'chosen</0>. It may no longer suit you because, for example, it contains more shares than you would like.</0>'
		+ '<0>Rebalancing <0>will ensure that each month, the percentage composition of your portfolio returns to your chosen '
		+ 'values</0>. You can turn off the rebalancing service - then ETFs are purchased for new deposits in such a\u00a0ratio that '
		+ 'they match the portfolio\'s target distribution.</0>',
	chart: {
		portfolioValueLabel: 'Portfolio value',
		portfolioToday: 'Today',
		portfolioAfterMonth: 'After a month',
		portfolioAfterRebalancing: 'After rebalancing',
		portfolioValueToday: '100,000',
		portfolioValueAfterMonth: '110,000',
		portfolioValueAfterRebalancing: '110,000',
		bond: 'Bonds',
		share: 'Stocks',
		etf: 'ETF',
		why: 'Why?',
		how: 'How?',
		tooltipWhy: 'Stock and bond prices change every day. In this case, '
			+ 'the prices of the stock ETF increased by 20% while the prices of the bond ETF remained unchanged.',
		tooltipHow: '5% of the portfolio held in the stock ETF was sold and bond ETF was purchased to rebalance back to 50%:50%.',
	},
};

const howItWorksSchema = {
	title: 'How exactly does it\u00a0work?',
	chart: {
		broker: 'Broker',
		client: 'Client',
		money: {
			title: 'Money',
			tooltip: 'When you invest, you send money from your current account to the broker´s designated account.'
				+ 'When Fondee returns money back to you, it also comes back directly from the broker\'s account.',
		},
		portfolio: {
			title: 'Portfolio selection',
			tooltip: 'You choose one of\u00a0the model portfolios. Fondee takes care of\u00a0this portfolio '
				+ 'and\u00a0gives orders to\u00a0buy or\u00a0sell ETFs\u00a0to the broker.',
		},
		trading: {
			title: 'Trading\u00a0orders',
			tooltip: 'Fondee gives orders to the broker to trade. '
				+ 'Your money is always invested on the next business day after receipt.',
		},
	},
	broker: {
		title: 'What is the role of a\u00a0broker?',
		text: 'Fondee is licensed by the Czech National Bank as a\u00a0portfolio manager, '
			+ 'but does not itself do trading or custody services.',
	},
};

const investorProfileAvatar = {
	modal: {
		title: 'Change the portfolio image',
		submit: 'Change the image',
		cancel: 'Keep current profile',
	},
};

const investorProfileChoice = {
	title: 'Which investor profile suits me best?',
	text: 'This investment profile was suggested based on your answers in\u00a0the\u00a0investment questionnaire. '
		+ 'You can change your investment profile in\u00a0your client dashboard at any time.',
	riskTitle: 'Your risk tolerance',
	submit: 'Confirm your selection',
	redo: 'I wish to go over the answers in\u00a0the investment questionnaire ',
	tooltip: {
		classic: 'This is our traditional offer that does not take ESG criteria into account.',
		esg: 'ESG investments take into account environmental impacts, social aspects and\u00a0responsible company management.',
	},
	category: {
		classic: 'One deposit = hundreds of stocks and bonds from around the world. Invest in ETFs online, simply and clearly.',
		esg: 'Values and returns go hand in hand. Invest sustainably in portfolios that have been beating traditional '
			+ 'investments in recent years.',
	},
	littleExperience: {
		title: 'Investing with\u00a0Fondee is not a\u00a0suitable choice for you as your knowledge and experience with\u00a0investing '
			+ 'is insufficient.',
		message: 'If you would like to find out more\u00a0about what an ETF is and\u00a0how Fondee works, '
			+ 'have a\u00a0look in <0>here</0>.',
		reset: 'I want to change some answers',
	},
	notSuitable: {
		title: 'Attention',
		message: 'We are sorry, but based on your responses we are unable to offer you our services at this time.',
		text: 'Investing with Fondee is not suitable for short-term speculation or other short-term goals. All investing '
			+ 'carries the risk of losing some or all of your investment, and products designed for short-term investing tend to '
			+ 'be much riskier to achieve the\u00a0desired return. Our services are designed for medium to long-term '
			+ 'investment in\u00a0diversified products.',
		back: 'Back to the questionnaire',
	},
	notRecommended: {
		title: 'Warning',
		message: '<0>Based on your answers, this investment profile is not suitable for you. Investing in financial '
				+ 'markets carries a\u00a0risk that you, based on your answers, are not or should not be willing to accept.</0>'
				+ '<0>If you marked a\u00a0wrong answer by mistake, or your answer is no longer valid, you can start the '
				+ 'questionnaire over.</0>',
		clientMessage: '<0>Based on your answers, this investment profile is not suitable for you. Investing in financial '
				+ 'markets carries a\u00a0risk that you, based on your answers, are not or should not be willing to accept.</0>',
		close: 'Start over',
		clientClose: 'Back',
		redo: 'I want to fill out the questionnaire again',
	},
	notConsistent: {
		title: 'Warning',
		text: 'We are sorry, but your answers regarding the\u00a0investment horizon are inconsistent. These are '
			+ 'the\u00a0questions "For how many years do you plan to\u00a0invest?" and "How do you plan to\u00a0use your investment money?". '
			+ 'By clicking the\u00a0button, you can go back to\u00a0the\u00a0questionnaire and correct your answers.',
	},
};

const investorProfileDetail = {
	title: 'Set up your ideal investment',
	subtitle1: 'For yourself, or for children? More stocks, bonds, or half and half? Classic or "green"? '
		+ '<0>It depends only on you!</0> Choose a\u00a0product, type of investment and risk level – we will suggest a\u00a0suitable '
		+ 'portfolio for you.',
	investorPostfix: 'investor',
	showProducts: 'View the approximate ETF allocation in the\u00a0portfolio',
	productsTitle: 'Allocation of ETF titles in the\u00a0portfolio',
	productToggle: 'I want an investment account:',
	portfolioToggle: 'Portfolio type:',
	selectionTitle: 'Use the slider to adjust the risk level',
	ranger: {
		info: 'In general, the willingness to tolerate a\u00a0higher level of risk and a\u00a0longer investment '
			+ 'time horizon, bridging short-term turbulences, leads to higher yields.',
		less1: 'Less\u00a0risk',
		less2: 'Lower\u00a0yield',
		more1: 'More\u00a0risk',
		more2: 'Higher\u00a0yield',
		recommend: 'Based on your answers in the investment questionnaire, we evaluated the profiles in the\u00a0<0>green</0> interval as '
				+ 'suitable for you.',
		recommendWarning: 'Profiles in the\u00a0grey interval are not suitable for you according to the evaluation of the '
				+ 'investment questionnaire.',
	},
	profileAccountOptions: [
		{
			label: 'For myself or for children',
			value: 'both',
		},
	],
	profileTypeOptions: [
		{
			label: 'Classic',
			value: 'classic',
		},
		{
			label: 'ESG',
			value: 'esg',
		},
	],
};

const investorProfilePreview = {
	toggleLabel: 'Select a portfolio type:',
	classicPortfolios: 'Classic',
	esgPortfolios: 'ESG',
	walletTitle: 'Your savings',
	sliderTitle: 'investor',
	chartOverlayTitle: 'Historical yield',
	chartOverlayPeriod: 'per year / per annum',
	chartTooltip: 'Average annual portfolio yield for the period 2014-2024 in CZK. The calculation is built on '
		+ 'simulated historical development based on the prices of individual ETFs or their underlying indices. These '
		+ 'are gross revenues before fees.',
	disclaimer: {
		text: 'Average annual portfolio growth over the period 2014-2024 in Czech crowns. The calculation is based '
			+ 'on a\u00a0simulated historical development based on the prices of individual ETFs or their underlying indices. '
			+ 'This is a\u00a0gross return before fees (1.09% per annum).',
	},
	aboutButton: 'Find out more',
};

const login = {
	title: 'Login',
	text: 'This way to the client zone',
	button: 'Login',
	forgotPassword: 'Forgotten password',
	social: {
		twoFactorTitle: 'Login via social\u00a0networks',
		or: 'or',
		title: 'Login via:',
	},
};

const media = {
	title: 'Fondee in the media',
};

const meta = {
	title: 'Fondee',
	titleExtended: 'Fondee | Where money makes money',
	titleSuffix: ' - Fondee',
	description: 'Join the next generation of investors. Don\'t let the money lie in the accounts where '
		+ 'it loses value. Invest it to let it appreciate on its own.',
	og: {
		title: 'Fondee',
		description: 'Don\'t let money lie in accounts where it loses value. Invest it to let it appreciate on its own.',
		referralTitle: 'Fondee - with a\u00a0bonus code',
		referralDescription: 'Start investing with a\u00a0bonus from a\u00a0friend.',
	},
	titles: {
		account: 'My account',
		childProduct: 'Investing for children',
		classicProduct: 'Tailor-made investing',
		clientCheck: 'Client check',
		contact: 'Ask us',
		cookies: 'Cookies',
		dashboard: 'Dashboard',
		documents: 'Documents',
		emailAlias: 'Account connect',
		emailVerification: 'Email verification',
		esgProduct: 'ESG investments',
		faq: 'FAQ',
		fees: 'Pricing',
		forgotPassword: 'Password reset',
		history: 'History',
		howItWorks: 'How it works',
		login: 'Login',
		offers: 'Something extra',
		onboarding: 'Onboarding',
		onboardingChild: 'Child registration',
		onboardingParent: 'Parent registration',
		ourApproach: 'About us',
		pensionProduct: 'Investing for retirement | Long-term investment product',
		portfolio: 'Portfolio',
		privacyPolicy: 'Privacy policy',
		referrals: 'Referrals',
		registration: 'Registration',
		resetPassword: 'Password change',
		signAgreement: 'Agreement signing',
		signNewAgreement: 'Agreement signing',
		studentProduct: 'Affordable investments for students | Easy & online',
		taxes: 'Taxes 2023',
		unsubscribe: 'Unsubscribe',
	},
	descriptions: {
		studentProduct: 'Investments for students with low fees and contributions from family and loved ones. Invest in ETF portfolios online using the Fondee app.',
	},
};

const offers = {
	title: 'This could also come in handy',
	text: 'We got together with a handful of Czech startups and prepared little something just for you.',
	code: 'Reference code',
};

const onboarding = {
	title: 'Welcome to Fondee!',
	text: 'You\'re about to go through a boring but necessary registration process. The good news is, it will make managing your investments that much simplier.',
	info: 'You\'ll need your identity card/driving licence/passport.\nYou\'ll be required to take a\u00a0photo of one of '
		+ 'your personal documents during the process. Therefore you will need either a\u00a0smart phone or a\u00a0computer '
		+ 'with a\u00a0webcam. And\u00a0a\u00a0little bit of patience as well.',
	steps: {
		product: {
			title: 'Product Selection',
			childInfo: 'Before we create a\u00a0new account for your child, we must first create an\u00a0account for you. We understand '
				+ 'that this is a bit of a\u00a0nuisance, but we need to be sure that everything is in order.',
		},
		profileType: {
			title: 'Portfolio type',
		},
		questionnaires: {
			title: 'Questionnaires',
			childTitle: 'Child\'s questionnaires',
			AML: 'AML questionnaire',
			ESG: 'ESG questionnaire',
			investment: 'Investment questionnaire',
		},
		amlQuestionnaire: {
			title: 'AML questionnaire',
		},
		questionnaire: {
			title: 'Questionnaire',
			active: 'The investment questionnaire consists of some simple questions with the aim of assessing your investment '
				+ 'experience and\u00a0knowledge and\u00a0also establishing your risk\u00a0attitude. We will then compare everything with\u00a0your choice of '
				+ 'investor profile, which you may change at any time. The investment questionnaire is a mandatory part '
				+ 'of the registration process and\u00a0the answers are included in the\u00a0contract.',
			activeChild: 'Fill out the investment questionnaire on behalf of your child. Where possible, fill in\u00a0the\u00a0answers '
				+ 'according to your child\'s situation. For example, investment goals or financial background. On the\u00a0other hand, '
				+ 'fill in\u00a0the\u00a0experience and knowledge according to your person.',
			signAgreementActive: 'The investment questionnaire is a bit more extensive and\u00a0contains some new questions. It is not '
				+ 'because we want more information from you, but because we must comply with the relevant legislation and\u00a0the extended '
				+ 'security regulations that we now have to abide by. Your answers are of course confidential and\u00a0they are subject to '
				+ 'personal data protection.',
			done: 'You have successfully filled out the questionnaire! You will find your answers in the annex to the contact.',
			continue: 'Continue to the next step',
			select: 'Select one of the options',
			agree: 'I agree',
			amlTitle: 'We have a\u00a0few more questions regarding the origin of the invested money',
			years_one: '{{ count }} year and less',
			years_other: '{{ count }} years and more',
			ratio_min: 'Less than 10%',
			currency: 'CZK',
			cancelModal: {
				title: 'We respect your decision.',
				text: 'If you\'d like to go back to\u00a0investing with\u00a0Fondee, don\'t hesitate to contact us at '
					+ '<0>$t(email.address)</0> or using the chat box on\u00a0this webpage.',
				return: 'Abort the\u00a0order to cancel account',
				logout: 'Sign\u00a0out',
			},
			americanModal: {
				title: 'Warning',
				text: 'We are sorry, but we are currently unable to offer you our services. If this situation changes in the\u00a0future, '
					+ 'we will let you know. If you do not wish to be contacted, you can cancel your registration via the\u00a0link below. '
					+ 'If you have any questions, do not hesitate to contact us at info@fondee.cz.',
				link: 'Cancel registration',
				button: 'Let me know',
				thanks: 'Thank you for your interest in Fondee!',
			},
			esgRatioModal: {
				title: 'Warning',
				text: '<0>At Fondee, we now offer the so-called "A8\u00a0product" (share in portfolio 100%) in our sustainable investment offer.</0>'
					+ '<0>What does it mean? A8 contains funds supporting the\u00a0environment, social aspects and\u00a0good corporate governance.</0>'
					+ '<0>If you are interested in a\u00a0different type of sustainable investment than the\u00a0A8, unfortunately we cannot '
					+ 'offer you anything at this time.</0>'
					+ '<1>It\'s a\u00a0bit strange, but even though we now only offer one ESG investment category (A8), we have an\u00a0'
					+ 'obligation to ask you about your preferences. You can read more about the\u00a0regulation of sustainable investments '
					+ 'and the\u00a0RTS Act in the\u00a0article <0>"Everything about European ESG regulation"</0><1>'
					+ '<0>You can change your answers at\u00a0any time.</0>',
				button: 'Back',
				continue: 'I understand and want to continue',
			},
			esgFinalQuestion: {
				title: 'Warning',
				text: '<0>Your ESG investment preferences do not match any of the\u00a0products currently offered by Fondee '
					+ '(A8 product with 100% representation).</0>'
					+ '<0>Fondee ESG portfolios support sustainability in environmental and\u00a0social aspects, provided that the\u00a0company functions properly.</0>'
					+ '<0>If you prefer a\u00a0different type of ESG investment than the\u00a0A8 product, we cannot offer you any investment '
					+ 'products at this time. You can use the\u00a0Fondee if you adjust your sustainability preferences.</0>'
					+ '<0>If you decide to do so, please state the reason that led you to this decision. You can also return to the\u00a0'
					+ 'questionnaire and change your answers using the\u00a0button below.</0>',
				button: {
					continue: 'I want to change my ESG preferences so that my choice is the A8 product with 100% representation '
						+ 'and\u00a0I wish to invest with Fondee in ESG products.',
					change: 'I want to go back to the\u00a0ESG questionnaire and\u00a0change my answers',
				},
				declaration: '<0>I hereby declare that I have been informed by Fondee that it is currently unable to provide me '
					+ 'with the\u00a0investment service in a\u00a0manner that would suit my sustainable investment preferences.</0>'
					+ '<0>Based on this information, I decided to adjust my preferences and choose from Fondee products.</0>',
			},
			logout: 'Thank you for your interest in Fondee!',
		},
		documents: {
			title: 'Verification of your identity',
			childTitle: 'Child identity verification',
			error: 'We are sorry, there was an error while verifying your identity.',
			redirect: {
				title: 'Verify using Veriff',
				text: 'Veriff is our partner through which you can complete the verification in a\u00a0few minutes. We '
					+ 'recommend doing the process on a\u00a0mobile phone, and you can find more tips in the question '
					+ 'mark below.',
				listTitle: 'It is possible to upload the following document types:',
				list: '<0>Identity card</0>'
					+ '<0>Passport</0>'
					+ '<0>Driving license</0>',
				listChild: '<0>Identity card</0>'
					+ '<0>Passport</0>',
			},
			upload: {
				title: 'Manual verification',
				frontSide: 'Front side',
				backSide: 'Back side',
				list: {
					formats: '<0>Supported formats:</0>'
						+ '<1>PNG, JPG</1>',
					size: '<0>Size:</0>'
						+ '<1>Maximum size 4MB</1>',
				},
				fileTypes: {
					birthCertificate: 'Birth certificate',
					drivingLicense: 'Driving license',
					identification: 'Identity card',
					passport: 'Passport',
				},
				submit: 'Confirm',
			},
			tooltip: '<0>Need some tips & tricks to go through process? Enable camera permission in your mobile/computer browser. '
				+ 'Make sure the lighting is good, you have good enough camera and\u00a0sharp image.</0>'
				+ '<0>What to avoid? The reflections of light on your document/face, blurry photos or bad lighting.</0>'
				+ '<0>In case you tried it all and still haven\'t succeeded, let us know via chat or e-mail us at info@fondee.cz. '
				+ 'We\'ll be more than happy to help you out!</0>',
		},
		childData: {
			title: 'Child registration',
			form: {
				personalIdentificationNumber: {
					title: 'Personal identification number of the child',
					text: 'Please enter the child\'s birth number without the\u00a0slash.',
				},
				bankAccount: {
					title: 'Account number of the child (optional)',
				},
			},
		},
		childEmailVerification: {
			title: 'Child email verification',
			text: 'We have sent you instructions on how to verify your email address. It only takes a moment.',
			form: {
				code: {
					label: 'Verification code',
					cancel: 'Delete child\'s email',
					confirm: 'Confirm',
					skip: 'Skip verification, delete the child\'s email and continue',
					invalid: 'Invalid verification code',
				},
				email: {
					label: 'E-mail address',
					change: 'Change email',
					success: 'Sent to new e-mail',
				},
			},
		},
		personalData: {
			title: 'Personal data review',
			active: 'Here you can go over or complete your personal data.',
			student: {
				confirm: 'I have an ISIC/ITIC card',
				error: 'You must confirm to continue.',
				modal: {
					title: 'Warning',
					text: 'Unfortunately, you are over 26\u00a0years old and do not meet the\u00a0conditions for a\u00a0student account. '
						+ 'However, you can continue with the\u00a0registration and we will open a\u00a0standard account for you. '
						+ 'It has the\u00a0same investment options, only without the\u00a0reduced fee.',
					confirm: 'Continue without ISIC',
				},
			},
			form: {
				name: {
					title: 'Name',
					text: 'The name under which you will be registered:',
				},
				address: {
					title: 'Address of permanent residence',
				},
				contactAddress: {
					add: 'I want to provide another contact address',
					title: 'Contact address',
					confirm: 'I declare that the address I have provided is correct',
				},
				birthDate: {
					title: 'Date of birth',
				},
				placeOfBirth: {
					title: 'Place of birth',
				},
				nationality: {
					title: 'Nationality',
				},
				personalIdentificationNumber: {
					title: 'Personal identification number',
					text: 'Please fill in your personal identification number (no\u00a0slash).',
				},
				bankAccount: {
					title: 'Account number',
					text: 'In order to link the payment to\u00a0your investment account, we need to know the number of the account '
						+ 'from which you will be sending your money.',
					warning: 'Please note that the account must be registered under your name, '
						+ 'which\u00a0is\u00a0why we do not accept payments made through third-party payment services such as '
						+ 'Wise, Revolut or\u00a0Monese',
					childWarning: 'If your child has his/her own bank account, you can enter it here. The account should be held '
						+ 'directly in the child\'s name. For this reason, it is not possible to use services such as Wise, Revolut or Monese.',
					ibanText: 'If you wish to add a foreign account, enter it using an IBAN.',
				},
				confirm: {
					label: 'I hereby confirm that the above-stated data is true and up to date',
					error: 'You need to tick this box to continue.',
				},
				submit: 'Confirm',
			},
		},
		agreement: {
			title: 'Sign the agreement',
			active: 'All you need to do now is sign an investment\u00a0representation agreement, which will allow us to invest '
				+ 'your money based on the strategy of your choice (it will not allow us to do anything else, don\'t worry).',
			signAgreementActive: 'As a result of a change of\u00a0broker, certain legislative terms and\u00a0details have '
				+ 'been modified. However, no factual changes have taken place, Fondee will continue to invest your money '
				+ 'based on the profile you have selected. Nothing\u00a0more, nothing\u00a0less.',
			signNewAgreementActive: 'The contract addresses investing with Fondee. In the appendices you will find the\u00a0'
				+ 'terms and conditions, the\u00a0price list and the complaints policy. Please download it, read it carefully '
				+ 'and confirm below. We will send you an SMS\u00a0code to verify your identity.',
			download: 'Download the agreement in PDF',
			timeOutText: 'Please note that you will be signed out after 20\u00a0minutes for security reasons. If you need more '
				+ 'time to study the contract, that’s no problem. You can log in again at any time and continue with the '
				+ 'registration where you left off.',
			formAgreement: {
				infoText: 'We have drawn up an agreement for you. Download it, read it carefully and\u00a0agree '
					+ 'below. We will send an SMS\u00a0code to verify your identity.',
				readAgreement: {
					label: 'I have read the agreement',
					blocked: 'Please read the agreement first.',
					required: 'You need to confirm that you have read the agreement.',
				},
				smsSend: 'Send code',
				smsBlocked: 'We have sent the verification code. If you need a\u00a0new one, please wait a\u00a0minute.',
				smsText: 'We will send a verification code to this number: <0>{{ phoneNumber }}</0> Please use this code to sign the agreement.',
				phoneChange: {
					text: 'If you have entered an incorrect telephone number, you can change it <0>here</0>. Once saved, you will need to '
						+ 'resend your contract signing code. There is a cooldown period of one minute.',
					modal: {
						title: 'Change of phone number',
						placeholder: 'New phone number',
						button: 'Save',
					},
				},
				legalRepresentative: {
					label: 'I am a legal representative',
					download: 'Download declaration of legal representative',
				},
			},
			formCode: {
				code: {
					label: 'Enter the verification code',
					invalid: 'The verification code has six characters.',
					error: 'You forgot to fill in the verification code.',
				},
				error: 'The code you entered is invalid. Please go over it or ask for a new one. If you\'re having trouble with the signature process, contact us at <0>$t(email.address)</0>.',
				submit: 'Sign the agreement',
			},
		},
		payment: {
			title: 'Sending the first payment',
			active: 'In order to complete your registration, your bank account needs to be verified. You can send any amount you choose for '
				+ 'the verification, however we recommend depositing at least 10,000\u00a0CZK for an ideal allocation of funds in the portfolio.',
			sentMoney: {
				label: 'I confirm I have sent the initial payment in line with the instructions above',
				error: 'To continue, you need to send the payment.',
			},
			submit: 'Finish the registration',
		},
	},
	final: {
		title: 'Registration completed!',
		payment: {
			title: 'We\'re waiting for the payment to be processed',
			text: 'We are now waiting for your first payment to be credited to the broker\'s investment account. '
				+ 'This will probably happen on the next business day. We will immediately invest the money according to the '
				+ 'investment profile of your choice. Before investing, we will check all the money laundering requirements imposed by law. '
				+ 'If we need more information, we will be in touch.',
		},
		studentConfirmation: {
			title: 'Verification of the\u00a0study certificate pending',
			text: 'We are verifying your study certificate, which may take a\u00a0few business days. '
				+ 'If you have any questions, please do not hesitate to contact us via chat or at <0>info@fondee.cz</0>.',
		},
		profile: {
			title: 'Would you like to change your investor profile?',
			text: 'If you\'d like to change your investor profile before your first investment, you can do so after activating '
				+ 'your account in the \u201cMy account\u201d section. Any changes to the profile will always take effect when the portfolio is next rebalanced.',
		},
		topUp: {
			title: 'Not sure whether you sent your first payment right?',
			text: 'If you finished all the registration steps more than two workdays ago, please make sure that '
				+ 'the first payment from account no. {{ account }} was processed successfully. If not, send the payment one more time following <0>these</0> instructions.',
			newAccount: 'If you accidentally sent a\u00a0verification payment from another account, you can add it via the\u00a0button below. '
				+ 'The\u00a0same conditions apply to all additional accounts - they must be held in your own name with a\u00a0banking '
				+ 'institution based in the\u00a0EU. We will then match the initial payment with your new account.',
		},
		agreement: {
			title: 'Forgot where you\'ve downloaded your agreement?',
			text: 'Your agreement is available for download in the <0>Documents</0> section.',
		},
		contact: {
			title: 'Is there anything else you\'d like to know?',
			text: 'Use the chatbox in the bottom right corner or e-mail us at <0>$t(email.address)</0>.',
		},
		feedback: {
			title: 'We appreciate any feedback',
			done: 'Thank you for the feedback!',
			form: {
				intro: 'Let us know how satisfied you were with the registration process:',
				statusGood: 'Very satisfied',
				statusBad: 'Not satisfied at all',
				message: 'You can also leave a comment:',
				submit: 'Submit feedback',
			},
		},
	},
	profilePreview: {
		text: 'Selected investor profile:',
		info: 'The\u00a0investor profile can be changed at\u00a0any time in\u00a0the\u00a0future '
			+ 'after the\u00a0registration is completed.',
	},
};

const ourApproachAccount = {
	title: 'Eva and Honza also invest with Fondee',
	text: 'Check out their portfolio',
};

const ourApproachHero = {
	title: 'About us',
	text: 'Look, who´s behind Fondee',
	list: [
		{
			title: '17,000+ clients, 3\u00a0countries',
			text: '17,000+ clients from 3\u00a0countries are actively investing with us. They have entrusted their portfolio to '
				+ 'Fondee and are growing their savings simply, transparently and with low fees. Will you join them?',
		},
		{
			title: 'Supervision of the Czech National Bank',
			text: 'Just like other Czech financial institutions, we are also supervised by the CNB. Together with '
				+ 'the\u00a0Investor Compensation Fund, it ensures that your money is safe with us.',
		},
		{
			title: 'Partnership with J&T Bank',
			text: 'J&T Bank sees potential in us. It supports Fondee financially and by sharing industry experience, '
				+ 'thus contributing to the faster development of a\u00a0modern investment application that was missing here.',
		},
	],
	anchor: 'Who would you choose',
};

const ourApproachTeam = {
	title: 'Who would you choose to take care of\u00a0your\u00a0finances?',
	team: {
		eva: {
			name: 'Eva Hlavsová',
			text: '<0>Perhaps, someone who has got master\'s degree in <1>Economics from Cambridge University</1>? Someone '
				+ 'who gained valuable experience at <1>Morgan Stanley<1> in London? Or someone with experience from the Czech Ministry '
				+ 'of Finance and the European Bank for Reconstruction and Development?</0>'
				+ '<0>Well, then check out Eva, our founder.</0>',
			link: 'Eva´s profile on LinkedIn',
		},
		honza: {
			name: 'Honza Hlavsa',
			text: '<0>Or someone, who studied <1>economics at LSE in London</1> and worked in finance in the City of London for many '
				+ 'years where he took care of <1>billion-dollar portfolios</1>?</0>'
				+ '<0>This is Honza, our founder, and his experience.</0>',
			link: 'Jan\'s LinkedIn profile',
		},
		pavel: {
			name: 'Pavel Štěpánek',
			text: '<0>And how about Pavel Štěpánek? Pavel has brought <1>over 30 years of experience</1> in finance to Fondee. He '
				+ 'served as a\u00a0member of the <1>Czech National Bank\'s</1> Board of Directors, a\u00a0director of the <1>Czech Banking Association</1>, '
				+ 'a member of the Board of Directors of the European Bank for Reconstruction and Development or as Deputy Minister '
				+ 'of Finance. Today, among other things, he lectures at the University of Economics and Business.</0>',
			link: 'Pavel\'s profile on LinkedIn',
		},
	},
};

const portfolio = {
	overview: {
		valueTitle_one: 'Total portfolio value',
		valueTitle_other: 'Total value of all portfolios',
		netGrowthTitle: 'Net growth',
		netGrowthTitleTooltip: 'Net portfolio value movement minus deposits, withdrawals and rewards from Fondee.',
		netGrowthRatioTitle: 'Net growth in\u00a0%',
		netGrowthRatioTitleTooltip: 'The percentage movement of the\u00a0portfolio value is calculated from the\u00a0ratio of '
			+ 'the\u00a0current portfolio value to the\u00a0deposit amount adjusted for withdrawals and rewards. If there were '
			+ 'multiple deposits, withdrawals or rewards within a\u00a0period, the weighted value of the\u00a0deposits, withdrawals '
			+ 'or rewards is taken into account according to when they took place. For example, if you started with a\u00a0CZK\u00a0100,000 '
			+ 'investment six months ago, deposited another CZK\u00a0100,000 yesterday and the\u00a0current value of your portfolio '
			+ 'is CZK\u00a0210,000, the movement in value will be approximately 10% because the newly deposited CZK\u00a0100,000 '
			+ 'has not yet appreciated in value.',
		noActivePortfolio: 'You don\'t have any active portfolios',
	},
	select: {
		title: 'My portfolios',
		button: 'Create portfolio',
		disabledTooltip: 'The user will be activated after the\u00a0information is checked and the\u00a0first deposit is allocated.',
		back: 'Back',
		archive: {
			button: 'View archived portfolios',
			title: 'List of archived portfolios',
			name: 'Portfolio name',
			select: 'Show',
			back: 'Back to portfolios',
		},
	},
	new: {
		modal: {
			title: 'Select portfolio type',
			subtitle: 'You can choose to create a\u00a0pension portfolio.',
			input: 'Portfolio name',
			nameTooltip: 'The portfolio name is for your convenience only.',
			childInfo: 'If you don\'t finish setting up your children\'s portfolio, you can return to it at any '
				+ 'time in My Account -\u00a0My Children.',
			add: 'Create portfolio',
			close: 'Close',
			currency: 'Portfolio currency',
			currencyTooltip: 'This is a\u00a0currency in which you want to see the value of your portfolio on\u00a0the\u00a0Portfolio '
				+ 'and History page. You can change the\u00a0display currency at\u00a0any time and it does not affect the\u00a0composition '
				+ 'of your portfolio.',
			additional: 'Additional settings',
			rebalancingActive: 'Enable automatic rebalancing',
			rebalancingTooltip: 'Check this box if you want us to automatically rebalance your portfolio once a\u00a0month '
				+ 'according to the\u00a0selected investment profile. You can change the\u00a0option at any time in the\u00a0profile '
				+ 'settings. This option does not affect the\u00a0processing of deposits or withdrawals in any way.',
			newConsent: 'I agree to the new <0>terms and conditions</0>.',
			newConsentPension: 'I agree to the new <0>terms and conditions</0> for setting up a pension account.',
			errorClosure: 'It is not possible to create a\u00a0new portfolio because you have entered the\u00a0cancellation of '
				+ 'your account. You can abort the\u00a0cancellation in the\u00a0<0>My Account</0> section.',
			warning: {
				limit: {
					title: 'New portfolio cannot be created',
					text: 'Sorry, but the\u00a0maximum number of portfolios per user account is\u00a05.',
				},
				limitChild: {
					title: 'New portfolio cannot be created',
					text: 'Sorry, but the maximum number of portfolios per child account is 3.',
				},
				limitPension: {
					title: 'New portfolio cannot be created',
					text: 'Currently, it is possible to have only one pension account.',
				},
			},
			success: {
				title: 'New portfolio created',
				text: 'You have a\u00a0new portfolio! To\u00a0make it active, you need to send a\u00a0deposit to it. Click on '
					+ 'the\u00a0Portfolio tab in the menu and select it. On the\u00a0Portfolio page, select Invest money. '
					+ 'The\u00a0second option is to transfer money from an\u00a0existing portfolio. From the\u00a0menu, select '
					+ 'the\u00a0portfolio from which you want to\u00a0transfer money or a\u00a0portion of the\u00a0ETF, and on '
					+ 'the\u00a0Portfolio page, select Transfer to\u00a0another portfolio.',
			},
		},
		childList: {
			title: 'Name of the child',
			select: 'Select',
			addChild: 'Invite another child',
		},
		form: {
			name: {
				empty: 'You forgot to fill in the name of the portfolio',
				error: 'Portfolio name cannot begin with a\u00a0space',
				unique: 'Portfolio name already in use.',
			},
		},
		create: 'Add portfolio',
	},
	settings: {
		title: 'Portfolio settings',
		name: {
			label: 'Edit portfolio name',
			button: 'Change the name',
		},
		currency: {
			title: 'Portfolio display currency',
			success: 'Portfolio display currency changed. If you wish to send deposits in a\u00a0different currency, see the instructions <0>here</0>.',
		},
		additional: {
			title: 'Additional settings',
		},
		pensionConfirmation: {
			download: 'You can download the confirmation for the employer <0>here</0>',
		},
	},
};

const portfolioComposition = {
	title: 'Portfolio composition',
	profile: 'Investor profile:',
	plannedProfile: 'Planned investor profile:',
	profileTooltip: 'You may change your investor profile in\u00a0your<0>account settings</0>. The profile change '
		+ 'will only take effect after the portfolio rebalancing. View the up-to-date information in\u00a0the Trading section.',
	table: {
		columns: {
			name: 'Name',
			isin: 'ISIN',
			amount: 'Number of units',
			pricePerVolume: 'Price per unit',
			totalValue: 'Total value',
			information: 'Key Information for\u00a0Investors',
		},
		rows: {
			cash: 'Cash',
			exchange: 'Exchange rate',
			total: 'Total value of your portfolio',
			totalTooltip: 'The total value of the portfolio does not have to correspond precisely with the sum of the individual values due to the rounding of the lines.',
		},
	},
};

const portfolioFeesEtfs = {
	title: 'How ETF fees are calculated',
	text: 'Each ETF has its own total expense ratio (TER) which measures how much it costs.'
		+ 'TERs of\u00a0ETFs in our portfolios vary from 0.05% to\u00a00.47% of the total value your investment per annum.'
		+ 'Fondee portfolios comprise up to 14\u00a0different ETFs. The\u00a0average portfolio TER therefore depends on the individual'
		+ 'ETFs\' allocations in your portfolio. This is usually around 0.15% of\u00a0the total value of your investment per annum.'
		+ 'They are already included in the\u00a0value of the\u00a0ETFs, so you don\'t see them in the change history'
		+ 'of\u00a0your investment portfolio.',
	userFee: 'Your current total ETF fee is {{ fee }} per annum.',
	link: 'Check the total expense ratios',
	modal: {
		title: 'ETF fees',
		name: 'Name',
		ter: 'Annual ETF fee (TER)',
	},
};

const portfolioFeesOverview = {
	title: 'Portfolio management fees',
	table: {
		columns: {
			year: 'Per annum',
			month: 'This month *',
		},
		rows: {
			fondee: 'Fondee fees',
			etf: 'ETF fees',
			total: 'Total fees',
		},
		discount: 'discount until {{ date }}',
	},
	note: '*monthly fees are calculated based on the\u00a0final value of the portfolio on the last trading day of\u00a0the\u00a0month '
		+ 'and\u00a0they are deducted at\u00a0the\u00a0beginning of the following month',
	text: 'ETF fees are already included in\u00a0their\u00a0respective values, therefore they are not visible in the '
		+ 'investment account statements. We have stated them here for you to have an idea of\u00a0the total fees. You can find the fee '
		+ 'for each individual ETF in the Key Information for\u00a0Investors.',
};

const portfolioTable = {
	title_one: 'Portfolio',
	title_other: 'Portfolios',
	select: {
		label: 'Appreciation in:',
	},
	table: {
		columns: {
			name: 'Portfolio name',
			value: 'Value',
			appreciation: 'Net growth',
		},
		more: 'More',
		add: 'Add portfolio',
	},
};

const privacyPolicy = {
	title: 'Privacy policy',
	intro: '<0>Fondee a.s., with the\u00a0registered office at Rumunská 22/28, Vinohrady, 120\u00a000 Prague\u00a02, ID '
		+ 'No.: 06691862, file reference B\u00a027322 kept by the\u00a0Municipal Court in Prague, as the\u00a0data '
		+ 'controller (hereinafter referred to as the\u00a0“<0>Controller</0>”),</0>'
		+ '<0>pursuant to Article 13 of Regulation (EU) No. 2016/679 of the\u00a0European Parliament and of the\u00a0'
		+ 'Council of 27\u00a0April 2016 on the\u00a0protection of individuals with regard to the\u00a0processing of '
		+ 'personal data and on the\u00a0free movement of such data and repealing Directive 95/46/EC, the\u00a0'
		+ 'General Data Protection Regulation (hereinafter referred to as the\u00a0“<0>GDPR</0>”),</0>'
		+ '<0>hereby informs its clients (hereinafter “<0>Data Subjects</0>”, individually as the\u00a0„<0>Data Subject</0>”) about:',
	text: '<0>Pursuant to the\u00a0license of the\u00a0Czech National Bank, the\u00a0Controller is authorized to offer and '
		+ 'provide the\u00a0following principal investment services: (i) receiving and transmitting orders '
		+ 'concerning financial instruments within the\u00a0meaning of Section\u00a04(2)(a) of the\u00a0Capital Market '
		+ 'Undertakings Act (hereinafter „<0>CMUA</0>“), (ii) management of the\u00a0client\'s assets, which include '
		+ 'a financial instrument, on a\u00a0discretionary basis within the\u00a0framework of a\u00a0contractual '
		+ 'arrangement within the\u00a0meaning of Section\u00a04(2)(d) of the\u00a0CMUA, or (iii) investment advisory '
		+ 'services relating to financial instruments within the\u00a0meaning of Section\u00a04(2)(e) of the\u00a0CMUA, '
		+ 'in relation to collective investment securities and financial instruments pursuant to Section\u00a0'
		+ '3(1)(d) of the\u00a0CMUA (all these investments services hereinafter „<0>Activity</0>“).</0>'
		+ '<0>the\u00a0Controller processes the\u00a0personal data, that are provided by the\u00a0Data Subjects, to the\u00a0'
		+ 'extent necessary for the\u00a0purposes and for the\u00a0timeframe necessary for fulfilling the\u00a0purposes. </0>'
		+ '<0>The\u00a0Controller processes the\u00a0Data Subjects‘ personal data for the\u00a0purpose </0>'
		+ '<1><0>of his Activity, i.e. closing or changing the\u00a0contract and fulfilling the\u00a0contract, '
		+ 'including the\u00a0potential calling and requests of the\u00a0Data Subject; </0>'
		+ '<0>of fulfilling the\u00a0legal duties of the\u00a0Controller, p. ex. the\u00a0regulations regulating the\u00a0'
		+ 'Activity of the\u00a0Controller, and especially legal obligations concerning the\u00a0consumer '
		+ 'protection, data archivation, accounting and tax administration; </0>'
		+ '<0>of legitimate interests of the\u00a0Collector, such as processing the\u00a0personal data for the\u00a0'
		+ 'purposes of litigation, administrative or similar proceedings or debt recovery, but '
		+ 'only if the\u00a0interests and rights of the\u00a0Data Subject do not prevail over the\u00a0legitimate '
		+ 'interests of the\u00a0Controller. For the\u00a0legitimate interests, the\u00a0Controller also '
		+ 'processes the\u00a0data for internal administrative purposes (internal evidence, '
		+ 'reporting, etc.), for quality assurance and risk management; and </0>'
		+ '<0>set by the\u00a0Data Subject in agreement with the\u00a0Cookies Policy. The\u00a0Data Subject '
		+ 'can agree with all the\u00a0cookie files or only with a\u00a0selected group of cookie files. This '
		+ 'agreement is voluntary and the\u00a0Data Subject may withdraw the\u00a0consent at any '
		+ 'time. The\u00a0agreement is required for the\u00a0processing of the\u00a0data of specific categories '
		+ '(health conditions) and personal data to be used for marketing purposes.</0></1>'
		+ '<0>After fulfilling the\u00a0original purposes (for example, fulfilling the\u00a0contract) the\u00a0Controller may '
		+ 'process the\u00a0personal data for different purposes (for example to fulfil the\u00a0legal requirements '
		+ 'concerning the\u00a0archivation of the\u00a0documents). </0>'
		+ '<0>For the\u00a0interests above, the\u00a0Controller processes especially the\u00a0data below:</0>'
		+ '<1><0>Identity and contact data: academic title, name, surname, date of birth, place of '
		+ 'birth, birth number, ID data (including a\u00a0photocopy of ID taken with the\u00a0beginning '
		+ 'of the\u00a0contract or during the\u00a0performance of the\u00a0contract in accordance with '
		+ 'regulations), sex, and contact address;</0>'
		+ '<0>Further data about the\u00a0Data Subject or concerning the\u00a0Data Subject, par. example '
		+ 'marital status or registration referral code;</0>'
		+ '<0>Electronic contact data: cell phone number, e-mail address;</0>'
		+ '<0>Other electronic data: IP address, device location;</0>'
		+ '<0>Further personal data necessary for the\u00a0contract performance: bank account '
		+ 'number, amount of payments and their history;</0>'
		+ '<0>Data about the\u00a0Data Subject required by legal regulations (par. ex. In accordance '
		+ 'with Act No. 253/2008 Coll., on certain measures against the\u00a0legitimization of the\u00a0'
		+ 'proceeds of crime and financing of terrorism (hereinafter „AML Act“)), including the\u00a0'
		+ 'photography taken by the\u00a0Data Subject, Controller or the\u00a0third party contractually '
		+ 'bound to the\u00a0Controller for the\u00a0purpose of verifying the\u00a0conformity of the\u00a0'
		+ 'photography with the\u00a0image in the\u00a0identity document in the\u00a0sense of the\u00a0AML '
		+ 'Act,and data taken from publicly accessible and inaccessible registers;</0>'
		+ '<0>other personal data provided by Data Subject in the\u00a0contract or other documents, '
		+ 'within the\u00a0telephone or e-mail communication, and </0>'
		+ '<0>records of phone calls and data from these calls.</0></1>'
		+ '<0>The\u00a0Controller obtains personal data directly from the\u00a0Data Subjects (especially in the\u00a0case of '
		+ 'contract negotiations and other communication with the\u00a0Data Subject), from third parties '
		+ '(especially from state administration bodies in the\u00a0performance of legal obligations or on the\u00a0'
		+ 'basis of special legal regulations), from publicly available sources (especially public registers) '
		+ 'or from their own activities (especially by evaluating and analyzing personal data obtained '
		+ 'from other sources mentioned above). The\u00a0Administrator also obtains personal data to a\u00a0'
		+ 'limited extent automatically by using cookies on the\u00a0Administrator\'s website.</0>'
		+ '<0>The\u00a0personal data of the\u00a0Data Subject will be processed fairly, lawfully and transparently. Only '
		+ 'personal data that are adequate, relevant and limited to what is necessary with regard to the\u00a0'
		+ 'purpose will be processed. </0>'
		+ '<0>The\u00a0Data Subject\'s personal data will be processed in a\u00a0manner ensuring proper security, '
		+ 'including the\u00a0protection by means of appropriate technical or organizational measures, against '
		+ 'unauthorized or unlawful processing and against accidental loss, destruction or damage.</0>'
		+ '<0>The\u00a0Controller processes only the\u00a0data collected in accordance with GDPR and it is his duty '
		+ 'to update the\u00a0data. For this purpose, the\u00a0Data Subjects must declare any changes in their data '
		+ 'and provide proof of the\u00a0change to the\u00a0Collector without delay. </0>'
		+ '<0>Personal data of the\u00a0Subjects will be processed for the\u00a0period of validity of the\u00a0contract or its '
		+ 'administration by the\u00a0Administrator and subsequently, as long as the\u00a0legitimate interest lasts '
		+ '(especially during the\u00a0limitation period for performance of the\u00a0contract (max. 10 years) from its '
		+ 'termination or from the\u00a0date of termination of administration and in case of court arbitration, '
		+ 'execution or other similar proceedings for the\u00a0duration of these proceedings and subsequently '
		+ 'for the\u00a0period of time for filing extraordinary remedies and during proceedings on filed '
		+ 'extraordinary remedies), as well as for the\u00a0Data subject\'s consent to processing if consent has '
		+ 'been granted. the\u00a0existence of a\u00a0legal obligation that applies to the\u00a0Administrator (financial '
		+ 'administration, supervisory body, archiving regulations) or for the\u00a0purposes of the\u00a0legitimate '
		+ 'interests of the\u00a0Administrator (litigation). The\u00a0Controller processes personal data exclusively '
		+ 'for defined purposes in accordance with the\u00a0existing legal title for processing. If the\u00a0contract '
		+ 'with the\u00a0Data Subject is not concluded, the\u00a0Administrator processes the\u00a0personal data '
		+ 'obtained in connection with the\u00a0conclusion of the\u00a0contract for a\u00a0period of five years. Records '
		+ 'of calls to the\u00a0customer line are recorded by the\u00a0Administrator for a\u00a0period of five years.</0>'
		+ '<0>The\u00a0Data Subject has the\u00a0right to withdraw consent to the\u00a0processing of his/her personal data '
		+ 'or explicit consent to the\u00a0processing of a\u00a0special category of personal data at any time, without '
		+ 'prejudice to the\u00a0lawfulness of the\u00a0processing based on the\u00a0consent given prior to its '
		+ 'withdrawal. More detailed information on the\u00a0withdrawal of consent to the\u00a0use of cookies can '
		+ 'be found in the\u00a0<0>Cookie Policy</0>.</0>'
		+ '<0>For the\u00a0purposes of the\u00a0Controller´s Activity, the\u00a0Data Subject´s personal data may be '
		+ 'provided to entities operating investment services and other financial institutions in a\u00a0'
		+ 'contractual relationship with the\u00a0Controller, the\u00a0Controller´s tied agents, authorized employees '
		+ 'and persons providing IT, development, legal, accounting and consulting services to the\u00a0'
		+ 'Controller in order to ensure proper performance of the\u00a0obligations laid down in generally '
		+ 'binding legal regulations and contractual obligations. Furthermore, personal data may also be '
		+ 'disclosed under the\u00a0law to prosecution authorities, courts, the\u00a0CNB, tax administrators, tax '
		+ 'authorities, executors or insolvency administrators, the\u00a0CIB and other public authorities. </0>'
		+ '<0>If the\u00a0Data Subject provides the\u00a0data of other persons, which are processed by the\u00a0Controller '
		+ 'during the\u00a0performance of the\u00a0contract, the\u00a0responsibility of fulfilling the\u00a0personal data '
		+ 'protection obligations towards these persons (especially informing them about the\u00a0processing '
		+ 'of personal data) lies on this Data Subject.</0>'
		+ '<0>The\u00a0Controller is not planning to transfer the\u00a0Data Subject’s personal data to a\u00a0third country '
		+ '(i.e. outside the\u00a0European Union or European Economic Area) or to an international '
		+ 'organization. However, if the\u00a0Data Subject has accepted or selected cookies provided by '
		+ 'Google, Facebook, Twitter or YouTube, he/she has expressly consented to the\u00a0transfer of '
		+ 'personal data to third countries (especially the\u00a0USA). If this is the\u00a0case, the\u00a0Controller draws '
		+ 'attention to the\u00a0fact that after the\u00a0transfer of personal data to third countries, the\u00a0Controller '
		+ 'does not have sufficient legal and factual tools to ensure the\u00a0security of users\' personal data. </0>'
		+ '<0>The\u00a0Controller has not appointed a\u00a0data protection officer but has designated a\u00a0person '
		+ 'responsible for this area who supervises the\u00a0proper processing of the\u00a0Data Subject\'s personal '
		+ 'data.</0>'
		+ '<0>The\u00a0Data Subject has the\u00a0right to request from the\u00a0Controller access to his/her personal data, '
		+ 'and to obtain a\u00a0copy of personal data processed by the\u00a0Controller, their rectification or erasure '
		+ 'or restriction of processing, and to object to the\u00a0processing, the\u00a0right to the\u00a0portability of such '
		+ 'data to another controller, as well as the\u00a0right to lodge a\u00a0complaint with the\u00a0Office for Personal '
		+ 'Data Protection (Pplk. Sochora\u00a027, 170\u00a000 Prague\u00a07, website: <1>www.uoou.cz</1>) if he/she believes '
		+ 'that the\u00a0Controller is processing personal data in violation of the\u00a0GDPR. Also, he/she may '
		+ 'exercise his/her rights in the\u00a0competent court. </0>'
		+ '<0>The\u00a0contact details of the\u00a0Controller for the\u00a0processing of personal data: </0>',
	contact: '<0>Jan Hlavsa</0>'
		+ '<0>Email: <0>honza@fondee.cz</0></0>',
	consent: {
		title: 'To withdraw consent to the\u00a0processing of your personal data, please send the\u00a0'
			+ 'following information to <0>info@fondee.cz</0>:',
		text: '<0>Withdrawal of consent to the\u00a0processing of personal data </0>'
			+ '<1>Name:</1>'
			+ '<1>Last Name:</1>'
			+ '<1>E-mail:</1>'
			+ '<1>Phone:</1>',
		email: 'info@fondee.cz',
	},
};

const products = {
	child: {
		title: 'Investing for children',
		text: 'Don\'t let your children\'s savings sit in the\u00a0bank where they lose value. Open for them an\u00a0online investment '
			+ 'portfolio at a\u00a0<0>reduced fee</0>. One standing order and you\'re done!',
		button: 'Start investing for children',
		profileDetail: {
			title: 'Explore portfolios for children',
		},
		fees: {
			title: 'Fee',
			text: '<0>The fee is calculated from the value of the portfolio and once a\u00a0month we deduct 1/12 of the annual fee. The price includes:</0>',
			list: '<0>processing of deposits and withdrawals in unlimited quantities</0>'
				+ '<0>unlimited number of ETF purchases and sales</0>'
				+ '<0><1>rebalancing</1> (if you don\'t want to turn it off voluntarily)</0>'
				+ '<0>processing of incoming dividends (applies to classic portfolios)</0>'
				+ '<0>currency conversion EUR↔CZK at the current market rate</0>'
				+ '<0>tax documents for the possible tax return submission</0>'
				+ '<0>customer support via chat, e-mail and phone</0>',
		},
		terms: {
			title: 'Terms and conditions',
			text: '<0>Access and ownership of investments</0>'
				+ '<1>An investment account is created by a\u00a0parent (or other legal representative) in the name of the child who '
				+ 'owns the account. If the child has its own e-mail address, it is possible to set up an access for the child as '
				+ 'well to see the behaviour of investments.  All rights are transferred to the child when it comes to an age of '
				+ '18th birthday and the account becomes a\u00a0classic investment account with regular fee.  The child may decide to '
				+ 'withdraw all funds or just a\u00a0part of it, or to keep the investments growing.</1>'
				+ '<0>Portfolios and deposit limits</0>'
				+ '<1>One portfolio isn´t enough, because you want to invest in several goals of your child?  Within each child´s account, '
				+ 'it is possible to have up to 3 portfolios with different investment profiles. Not only parents, but also other '
				+ 'relatives or acquaintances may deposit money into children´s portfolios! Deposited funds are credited to the '
				+ 'portfolio based on the note to the recipient when the payment is sent. Parents, children and other persons with a\u00a0'
				+ 'verified bank account with Fondee can deposit money into children´s portfolios without limit. Deposits from third '
				+ 'parties (e.g. other relatives or acquaintances who are not Fondee clients) are limited to <0>CZK 23,000</0> '
				+ '(from one bank account) per year per child. </1>'
				+ '<0>Money withdrawal before 18th birthday</0>'
				+ '<1>In case you need to withdraw your funds before your child´s 18th birthday, no worries - we will deduct the '
				+ 'difference between the fee you have paid so far and our regular fee from the amount. The transfer can take '
				+ 'up to a\u00a0week. </1>',
		},
		benefits: {
			title: 'Why invest for children with Fondee?',
			list: [
				'<0>Lower fees</0>'
				+ '<1>We\'re happy to support you in your long-term investment for children, so we offer a\u00a0discounted fee of 0.24% '
				+ '(0.85% lower than other Fondee investments!) on children\'s accounts.</1>',
				'<0>Secure your future</0>'
				+ '<1>Give your children an amount that will really help them in the future. In a\u00a0few years, today\'s thousand won\'t '
				+ 'buy the same what it does now. Unlike savings or building savings, investments have a\u00a0chance to beat <4>inflation.</4></1>',
				'<0>Education of children</0>'
				+ '<1>It\'s not just about money for school or first home. Children can have their own access to their Fondee '
				+ 'account and watch how the investment is doing. This way they learn about finance and gain valuable experience.</1>',
			],
		},
	},
	classic: {
		title: 'Tailor-made investing',
		text: 'Investments so simple they work on their own. Let your money work for you - online, with low fees and <0>from 1,000\u00a0CZK</0>. '
			+ 'Choose from 7\u00a0classic portfolios and invest in hundreds of stocks and bonds from around the world.',
		button: 'Start investing',
		profileDetail: {
			title: 'Explore classic portfolios',
		},
		fees: {
			title: 'Fee',
			text: '<0>The fee is calculated from the value of the portfolio and once a\u00a0month we deduct 1/12 of the annual fee. The price includes:</0>',
			list: '<0>processing of deposits and withdrawals in unlimited quantities</0>'
				+ '<0>unlimited number of ETF purchases and sales</0>'
				+ '<0><1>rebalancing</1> (if you don\'t want to turn it off voluntarily)</0>'
				+ '<0>processing of incoming dividends (applies to classic portfolios)</0>'
				+ '<0>currency conversion EUR↔CZK at the current market rate</0>'
				+ '<0>tax documents for the possible tax return submission</0>'
				+ '<0>customer support via chat, e-mail and phone</0>',
		},
		benefits: {
			title: 'Why invest with Fondee?',
			list: [
				'<0>Diversification</0>'
				+ '<1>Don\'t put your eggs in one basket. Fondee portfolios contain hundreds of stocks and bonds <0>from different '
				+ 'countries and industries</0>. Thanks to quality diversification, the value of your investment is not '
				+ 'threatened by the failure of one company or industry.</1>',
				'<0>Flexibility</0>'
				+ '<1>Fondee will adapt to you. Invest as much as you want. Invest when you want. Create up to '
				+ '<0>5 different portfolios</0> at the same time. And when you decide to withdraw the money, it is in your '
				+ 'bank account within <0>a\u00a0week</0>.</1>',
				'<0>Safety</0>'
				+ '<1>We are supervised by the <0>Czech National Bank</0> and your data is protected by encrypted communication. '
				+ 'It is <0>not possible to send your funds to someone else\'s account</0> or change personal data without '
				+ 'authorization and presentation of an identity document.</1>',
			],
		},
	},
	esg: {
		title: 'Sustainable investments',
		text: 'Profit or values? Get both! Invest sustainably in ESG portfolios and grow your money '
			+ 'with the good feeling that you are contributing to a\u00a0<0>better future</0> – yours and all of ours.',
		button: 'Start investing sustainably',
		profileDetail: {
			title: 'Explore ESG portfolios',
		},
		fees: {
			title: 'Fee',
			text: '<0>The fee is calculated from the value of the portfolio and once a\u00a0month we deduct 1/12 of the annual fee. The price includes:</0>',
			list: '<0>processing of deposits and withdrawals in unlimited quantities</0>'
				+ '<0>unlimited number of ETF purchases and sales</0>'
				+ '<0><1>rebalancing</1> (if you don\'t want to turn it off voluntarily)</0>'
				+ '<0>currency conversion EUR↔CZK at the current market rate</0>'
				+ '<0>tax documents for the possible tax return submission</0>'
				+ '<0>customer support via chat, e-mail and phone</0>',
		},
		benefits: {
			title: 'Why invest sustainably?',
			list: [
				'<0>Right values</0>'
				+ '<1>Do you want to invest in companies that have good financial results while contributing to a\u00a0more '
				+ '<0>sustainable future</0>? Choose responsible portfolios made up of ETFs with the best <1>ESG rating</1>.</1>',
				'<0>Revenue / Returns</0>'
				+ '<1>Sustainable ETFs have been <2>beating traditional ETFs</2> lately. ESG improves the financial results of companies '
				+ 'in the long term. In addition, the ETFs in our portfolios do not pay out, but <0>reinvest dividends</0> - and '
				+ 'thus their value increases.</1>',
				'<0>Lower risks</0>'
				+ '<1>ESG ratings consider <0>risks that ordinary financial analysis does not</0> even though these risks can affect '
				+ 'the performance of the entire company and the value of your portfolio. In addition, it turns out that responsible investments '
				+ '<3>fluctuate less in the long term</3>.</1>',
			],
		},
	},
	pension: {
		title: 'Pension investment account (DIP)',
		text: 'Take the future into <0>your own hands</0>! Do not rely solely on government pension schemes and say goodbye to funds with high fees and poor returns. Invest for your retirement simply, with <0>employer contributions, tax benefits and lower fees</0> compared to our regular portfolios.',
		button: 'I\u00a0want a\u00a0richer retirement',
		profileDetail: {
			title: 'Explore pension portfolios',
		},
		fees: {
			title: 'Fees',
			text: '<0>The fee is calculated from the current portfolio value and 1/12 of the annual fee is deducted once a\u00a0month. The fee includes:</0>',
			list: '<0>processing of unlimited <0>deposits and withdrawals</0></0>'
				+ '<0>unlimited <0>ETF purchases and sales</0></0>'
				+ '<0><1>rebalancing</1> (if you wish to rebalance your portfolio)</0>'
				+ '<0>processing of <0>incoming dividends</0> (applies to classic portfolios)</0>'
				+ '<0>currency conversion EURO↔CZK at the <0>current market rate</0></0>'
				+ '<0><0>tax documents</0> to make your tax claim easier</0>'
				+ '<0>customer support via chat, e-mail and phone</0>',
		},
		terms: {
			title: 'Conditions',
			text: '<0>Limits on age and number of portfolios</0>'
				+ '<1>Have you celebrated your <0>18th birthday</0> yet? Then there is nothing stopping you from opening '
				+ 'a\u00a0pension investment account with Fondee! You can set up <0>one pension portfolio</0> to invest for your old '
				+ 'age with an even more <0>favourable fee</0> compared to our regular portfolios.</1>'
				+ '<0>Withdrawals and transfers of funds</0>'
				+ '<1>The pension portfolio is used for <0>long-term investing</0>. You can therefore withdraw the money free of '
				+ 'charge and start enjoying it <0>after your 60th birthday</0>, provided that at least <0>10\u00a0years (120\u00a0months)</0> '
				+ 'have passed <0>since the creation of the pension portfolio</0>. It`s up to you whether you want to withdraw the entire '
				+ 'amount at once, or use it up gradually and let some of it continue to appreciate in value. However, it is not possible '
				+ 'to transfer money between pension and other Fondee portfolios.</1>'
				+ '<0>Tax savings and contributions from the employer</0>'
				+ '<1>Our pension account works as a\u00a0<0>long-term investment product (DIP)</0> - a\u00a0new government-backed '
				+ 'retirement security vehicle working in Czechia. This means that your employer will be able to contribute to the '
				+ 'pension portfolio with Fondee in a\u00a0similar way to a\u00a0classic pension. You can also deduct your DIP '
				+ 'deposits from your tax base, saving up to <0>CZK\u00a07,200</0> per year.</1>'
				+ '<0>Early withdrawal and portfolio cancellation</0>'
				+ '<1>If you need to withdraw money from the pension portfolio before your 60th birthday or before the 10th '
				+ 'anniversary of the contract, you can <0>cancel it earlier</0>. However, the conditions will be violated and we '
				+ 'will therefore charge additional fees up to our <0>standard amount of 1.09% per year</0>.</1>'
				+ '<1>If you use the pension account as a\u00a0long-term investment product (DIP), and if you draw tax benefits, in '
				+ 'the event of an early cancellation you will need to <0>file a\u00a0tax return</0> and <0>tax these savings ex-post</0> '
				+ 'in the same way as with pension savings or supplementary insurance.</1>',
		},
		benefits: {
			title: 'Why invest for retirement with Fondee?',
			list: [
				'<0>Safety net for old age</0>'
				+ '<1>Do you also no longer believe in fairy tales about the government pension and do you know that '
				+ '<0>saving does not beat inflation</0>? It`s time to take the future into your own hands. Secure '
				+ 'a\u00a0better pension and start investing -\u00a0<0>you can do it with us</0>.</1>',
				'<0>Lower fees</0>'
				+ '<1>We are glad that you want to treat yourself to a\u00a0richer retirement. To support you, we offer a\u00a0'
				+ '<0>discounted fee of 0.61%</0> per annum for pension portfolios (0.5% lower than other Fondee investments!).</1>',
				'<0>Long-term investment product</0>'
				+ '<1>Swap expensive pension funds with poor returns for a\u00a0retirement account from Fondee. It works as '
				+ 'a\u00a0long-term investment product with <0>tax advantages</0> and the possibility of <0>employer contributions</0>.</1>',
			],
		},
	},
	student: {
		title: 'Investing for students',
		text: 'Invest while you\'re still in school via the Fondee student account. Grow your savings smartly in ETF '
			+ 'portfolios <0>with a\u00a0reduced fee</0>, the possibility of contributions from loved ones and a\u00a0100\u00a0CZK bonus for ISIC.',
		button: 'Start investing',
		profileDetail: {
			title: 'Explore student portfolios',
		},
		fees: {
			title: 'Fees',
			text: '<0>The fee is calculated from the current portfolio value and 1/12 of the annual fee is deducted once a\u00a0month. The fee includes:</0>',
			list: '<0>processing of unlimited <0>deposits and withdrawals</0></0>'
				+ '<0>unlimited <0>ETF purchases and sales</0></0>'
				+ '<0><1>rebalancing</1> (if you wish to rebalance your portfolio)</0>'
				+ '<0>processing of <0>incoming dividends</0> (applies to classic portfolios)</0>'
				+ '<0>currency conversion EURO↔CZK at the <0>current market rate</0></0>'
				+ '<0><0>tax documents</0> to make your tax claim easier</0>'
				+ '<0>customer support via chat, e-mail and phone</0>',
		},
		terms: {
			title: 'Terms and conditions',
			text: '<0>How to create a student account</0>'
				+ '<1>Are you between <0>18 and 26\u00a0years old</0>? Come and invest in stocks and bonds from all over '
				+ 'the\u00a0world with us through <0><0>ETFs</0></0>, online, and at a\u00a0reduced fee. Prepare your '
				+ '<0>ISIC card</0> number or a\u00a0<0>certificate of study</0> with a\u00a0school stamp and upload it:</1>'
				+ '<1><2>'
				+ '<0>In\u00a0the\u00a0<0>My account section</0> in\u00a0the\u00a0client zone, if you <0>are already</0> '
				+ 'a\u00a0Fondee client (you have an\u00a0account with us with a\u00a0standard fee) -\u00a0we will arrange the\u00a0transfer '
				+ 'of your account to a\u00a0student account.</0>'
				+ '<0><0>During <0>registration</0></0>, if you are <0>not yet</0> a\u00a0Fondee client (you do not have an\u00a0account with us).</0>'
				+ '</2></1>'
				+ '<1>Our team will check the entered data and <0>activate your benefits</0>. You only need to '
				+ '<0>prove your studies once</0> and you can use your student account <0>until you are 26\u00a0years old</0>. If you '
				+ 'have filled in your ISIC number, you will also get a\u00a0<0>bonus of 100\u00a0CZK</0> to start! And that\'s a good deal, isn\'t it?</1>'
				+ '<0>Deposits and contributions from parents and relatives</0>'
				+ '<1>In Fondee you can choose from 7\u00a0portfolios from conservative to bold in\u00a0the\u00a0'
				+ '<0>classic or <2>sustainable ESG variant</2></0> -\u00a0you can open up to 5\u00a0different portfolios '
				+ 'at\u00a0once. Then you send deposits <0>in any frequency and amount</0>. It pays off to invest long-term and '
				+ 'regularly, but if you sometimes manage to put away more and sometimes less, nothing happens. Everyone according to '
				+ 'their own possibilities, <0>the\u00a0most important thing is to start</0>.</1>'
				+ '<1>You can also involve your <0>parents and relatives</0> in investing within your student account (they might '
				+ 'prefer to hear about it than buying new shoes or an\u00a0iPhone). They can send you contributions '
				+ '<0>directly to Fondee</0>, just give them the\u00a0<0>payment details</0> listed in\u00a0the\u00a0client zone '
				+ 'and tell them to include <0>your portfolio number</0> in\u00a0the\u00a0message to recipient so that the\u00a0money is credited correctly.</1>'
				+ '<1><2>'
				+ '<0>Anyone who is a\u00a0<0>Fondee client</0> has the opportunity to send you deposits '
				+ 'in\u00a0<0>unlimited amounts</0> from a\u00a0bank account linked to Fondee.</0>'
				+ '<0>Everyone else can contribute up to <0>23,000\u00a0CZK per year</0> from one account number.</0>'
				+ '</2></1>'
				+ '<0>Withdrawals and switching to a\u00a0standard account</0>'
				+ '<1>With a\u00a0student account up to 26\u00a0years of age, you can invest with a\u00a0<0>lower fee</0> '
				+ '(0.61%\u00a0per\u00a0year) and <0>unlimited withdrawals</0>. We understand that it is not easy to save up '
				+ 'a\u00a0sufficient financial reserve when you are young and anything can happen during your studies, so '
				+ 'we do not limit withdrawals in\u00a0any way and they will not affect the\u00a0fee. Just approach them with '
				+ 'caution -\u00a0investments work best when they can work for you <0>in\u00a0the\u00a0long term</0>.</1>'
				+ '<1>Once you turn 26, your Fondee account will switch <0>from student to standard</0> (with a\u00a0fee of 1.09%\u00a0per\u00a0annum).</1>',
		},
		benefits: {
			title: 'Why invest as a student with Fondee?',
			list: [
				'<0>Time is on your side</0>'
				+ '<1>Enjoy your studies, let the\u00a0compound interest do the\u00a0work. You can have a\u00a0nice investment '
				+ 'package in\u00a0just a\u00a0few years (and what about decades!). In\u00a0addition, you will build '
				+ '<0>healthy financial habits</0> from a\u00a0young age. The only thing left is the\u00a0first step – to start!</1>',
				'<0>A penny saved is a\u00a0penny earned</0>'
				+ '<1><8>Student life is tough enough</8>. That\'s why we offer a\u00a0<0>reduced fee of 0.61%\u00a0per\u00a0year</0> '
				+ 'on accounts for young people instead of the\u00a0usual 1.09%. You certainly don\'t have to put away thousands '
				+ 'right away, even small amounts and contributions from loved ones count.</1>',
				'<0>It\'s not rocket science</0>'
				+ '<1>It\'s never too early to invest and you don\'t even need a\u00a0degree in\u00a0economics. All you need is '
				+ 'a\u00a0mobile phone and the <0>desire to try it out</0>. Fondee is an\u00a0intuitive app and if anything happens, '
				+ '<0>we are here for you</0> in\u00a0chat and on\u00a0social media.</1>',
			],
		},
	},
	list: {
		title: 'Other products',
		classic: {
			title: 'Tailor-made investing',
			text: 'One deposit = hundreds of stocks and bonds from around the world. Invest in ETFs online, simply and clearly.',
			button: 'More information',
		},
		esg: {
			title: 'Sustainable investments',
			text: 'Values and returns understand go hand in hand. Invest sustainably in portfolios that have beaten classic investments in recent years.',
			button: 'More information',
		},
		child: {
			title: 'Investing for children',
			text: 'Don\'t let your children\'s savings sit in the bank where they lose value. Open for them an online investment'
				+ ' portfolio at a\u00a0reduced fee.',
			button: 'More information',
		},
		pension: {
			title: 'Pension investment account (DIP)',
			text: 'Secure a\u00a0richer retirement with smart, low-fee investments. Do not rely on the government and say goodbye to expensive funds.',
			button: 'More information',
		},
		student: {
			title: 'Investing for students',
			text: 'It\'s never too early to invest! Start while you\'re still in\u00a0school -\u00a0open a\u00a0student account '
				+ 'with a\u00a0discounted fee and ISIC bonus.',
			button: 'More information',
		},
	},
};

const productAccount = {
	title: 'Historical yield',
	toggleOptions: [
		{
			label: 'Value',
			value: 'value',
		},
		{
			label: 'Net growth',
			value: 'evaluation',
		},
		{
			label: 'Net growth in\u00a0%',
			value: 'evaluationPercent',
		},
	],
	note: 'The historical yield is based on the\u00a0average growth of the\u00a0ETFs (or their underlying indices) included in '
		+ 'the portfolio for the\u00a0selected period, in Czech crowns and before fees. Please note that historical '
		+ 'returns are not a\u00a0guarantee of future returns. Learn more about the risks of investing on '
		+ 'the\u00a0<0>How it works</0> page.',
};

const productSelect = {
	standard: 'Classic/ESG',
	child: 'Children',
	pension: 'Pension (DIP)',
	student: 'Student',
	studentBadge: 'Student',
};

const profileAvatar = {
	faces: {
		esg: {
			0: 'Profile image 1',
			1: 'Profile image 2',
			2: 'Profile image 3',
			3: 'Profile image 4',
			4: 'Profile image 5',
			5: 'Profile image 6',
			6: 'Profile image 7',
			7: 'Profile image 8',
			8: 'Profile image 9',
			9: 'Profile image 10',
			10: 'Profile image 11',
			11: 'Profile image 12',
			12: 'Profile image 13',
			13: 'Profile image 14',
		},
		classic: {
			0: 'Profile image 1',
			1: 'Profile image 2',
			2: 'Profile image 3',
			3: 'Profile image 4',
			4: 'Profile image 5',
			5: 'Profile image 6',
			6: 'Profile image 7',
			7: 'Profile image 8',
			8: 'Profile image 9',
			9: 'Profile image 10',
			10: 'Profile image 11',
			11: 'Profile image 12',
			12: 'Profile image 13',
			13: 'Profile image 14',
		},
	},
};

const referrals = {
	title: 'Recommend Fondee - and get a\u00a0good feeling in return!',
	text: 'If you like Fondee, we\'d be more than grateful if you recommend us to your friends. '
		+ 'Althought you won\'t get anything in return apart from a\u00a0good feeling, anyone who uses your reference code '
		+ 'will get a\u00a0<0>3-month free trial</0>. Not bad!',
	code: 'Reference code',
	copyLink: 'Copy the link to the Fondee website with the pre-filled reference code',
	form: {
		email: {
			label: 'E-mail where we will send the referral',
			error: 'You forgot to fill in the e-mail address.',
		},
		message: {
			label: 'Your message',
			error: 'You forgot to fill in the message.',
		},
		messageText: 'Congratulations, you have great friends! Who else could recommend investing with Fondee?\n'
			+ 'Plus, if you enter the reference code {{ code }} when you register at www.fondee.cz and open '
			+ 'an\u00a0investment account, we\'ll not charge fees for the first 3\u00a0months! And that\'s '
			+ 'a\u00a0great opportunity to try the service without worrying too much, isn\'t it?\n'
			+ 'If you have any questions about investing with Fondee, be sure to contact Irys and Kasia at '
			+ 'info@fondee.cz!',
		send: 'Send',
		success: 'Thank you for sharing your reference code. Send it to\u00a0other friends as well. ',
	},
	emailStats: {
		title: 'E-mail with reference code sent to:',
		used: 'Code used!',
	},
	socialShare: {
		facebook: 'Fondee - with a\u00a0bonus code. Start investing with a\u00a0bonus from a\u00a0friend.',
		twitter: 'This is my reference code at Fondee: {{ code }}. Why am I sharing it? Because if you open an '
			+ 'investment account with Fondee using it, you will get a\u00a0bonus: 3\u00a0months with Fondee without fees. And it pays off!',
		sendVia: 'Send via',
	},
	stats: {
		title: 'The number of clients that have registered using your\u00a0reference code:',
	},
};

const register = {
	title: 'Start investing',
	text: 'To start investing with Fondee takes just a\u00a0few simple steps',
	button: 'Register / Start investing',
	info: 'Fill in your personal data. The child\'s data in the case of a\u00a0child account is filled in '
		+ 'at\u00a0the\u00a0next step of registration.',
	social: {
		or: 'or',
		title: 'Register via:',
	},
	emailAlias: {
		title: 'Sign in to connect your account',
		text: 'If you already have an account, you can connect it here.',
		button: 'Connect account',
	},
	privateModal: {
		title: 'Attention, you are logging in with an anonymous Apple e-mail',
		text: 'Are you sure you want to create a\u00a0new account with an anonymous Apple e-mail?',
		button: 'Connect account',
		close: 'I do not have an account, I want to <0>create one</0>.',
	},
};

const resetPassword = {
	title: 'Change password',
	form: {
		button: 'Change password',
	},
};

const scrollToTop = {
	title: 'Back to the top',
};

const signAgreement = {
	title: 'Signing a new agreement, information update ',
	text: 'Since we will be trading under our new OCP licence at J&T\u00a0bank, we\'d like to ask you to fill out '
		+ 'an updated questionnaire and\u00a0sign a new agreement. We know this is annoying, but we have no choice '
		+ 'if we want to comply with the legislation.',
	info: 'If you have any questions, don\'t hesitate to contact Karol and\u00a0David at\u00a0<0>$t(email.address)</0>',
	done: 'Thank you for filling out the questionnaire and\u00a0signing the new agreement.\nThat\'s all we\u00a0need from you '
		+ 'at the moment. Thank you for being with\u00a0us! Yours sincerely,\u00a0Fondee',
};

const signNewAgreement = {
	title: 'Signing of the new agreement',
	text: {
		standard: 'If you are interested in our standard products, please sign a\u00a0new contract. The\u00a0contract '
			+ 'and its appendices regulate basic requirements such as opening an\u00a0account, the investment process '
			+ 'and service fees.',
		pension: 'Our pension portfolio works as\u00a0a\u00a0long-term investment product (DIP). We have prepared '
			+ 'a\u00a0new contract for you, which addresses the\u00a0conditions of the\u00a0DIP and which you can '
			+ 'present to your employer if necessary.',
	},
	child: {
		title: 'Signing a child contract',
		description: 'On this page, you can sign a new child account agreement in the event of a change in contact or personal information.',
	},
	info: 'If you have any questions, do not hesitate to contact us at\u00a0<0>$t(email.address)</0>.',
	contractTypes: {
		standard: 'My standard contract',
		pension: 'My pension contract',
	},
};

const studentConfirmation = {
	account: {
		title: 'Request a\u00a0student account',
		text: 'Are you a\u00a0high school or college student between 18 and 26? You can apply for a\u00a0student '
			+ 'account with lower fees. Simply upload a\u00a0valid ISIC card or study certificate. '
			+ 'We will approve your ISIC immediately, and study certificate within a\u00a0few business days. '
			+ 'More information in\u00a0the\u00a0FAQ section.',
		verification: {
			title: 'Verification of the\u00a0study certificate pending',
			text: 'We are verifying your study certificate, which may take a\u00a0few business days. '
				+ 'If you have any questions, please do not hesitate to contact us via chat or at <0>info@fondee.cz</0>.',
		},
	},
	onboarding: {
		text: 'Upload a\u00a0scan or photo of a\u00a0valid study certificate here.',
		subText: 'We need to verify your study certificate. In the meantime, you can continue with your registration. '
			+ 'Once we verify it, we will activate your student account benefits.',
	},
	success: 'Your account has been changed to a student account',
	toggle: {
		ISIC: 'ISIC',
		confirmation: 'Study certificate',
	},
	upload: {
		file: 'Upload',
		confirm: 'Submit',
		list: {
			formats: '<0>Supported formats:</0>'
				+ '<1>PNG, JPG, PDF</1>',
			size: '<0>Size:</0>'
				+ '<1>Maximum size 4MB</1>',
		},
		wrongFormat: 'Wrong format of the student certificate.',
	},
	manual: {
		code: 'ISIC',
		confirm: 'Submit',
	},
};

const survey = {
	close: 'Close',
	other: 'Other:',
	otherPlaceholder: 'Your answer…',
	validateError: 'Please fill in at least one option.',
	submit: 'Submit',
	thanks: 'Thank you!',
};

const taxes = {
	intro: '<0>The\u00a0taxation of investments may be a\u00a0tricky question. <0>Fondee has opened for you '
		+ 'a\u00a0separate section on taxation in\u00a0the\u00a0client zone</0> to help you orient yourself better '
		+ 'in\u00a0this topic.</0>'
		+ '<0><0>Here you will find your trading data from past years</0> processed to make the\u00a0submission '
		+ 'of\u00a0your tax return (if you have the\u00a0duty to do so) as easy as possible.</0>',
	link: 'Fondee tax statement for\u00a02023',
	title: '<0>To help you find out whether or not you have to file a\u00a0tax return and how to report your Fondee '
		+ 'investment incomes, we have prepared the\u00a0following questionnaire for you.</0>',
	text: '<0>If you want to\u00a0know how the\u00a0taxation of\u00a0investments works in\u00a0general, you can learn '
		+ 'more <0>here</0>.</0>'
		+ '<0>You can learn more about the\u00a0taxation of your incomes from your investments made via Fondee '
		+ 'in\u00a0the\u00a0extended <1>FAQ section</1> below –\u00a0The\u00a0most frequent questions '
		+ 'on\u00a0the\u00a0taxation of\u00a0investments.</0>',
	infoLink: '',
	modal: {
		title: 'How are investments taxed?',
		text: '<0>A very simplified explanation of the\u00a0issue may be as follows: Investment incomes may either be '
			+ 'exempted from tax (no tax is paid) or taxable. If your investment incomes are taxable, you do not'
			+ 'necessarily have to\u00a0tax such incomes if they are below the limit for mandatory filing of '
			+ 'a\u00a0tax return, which is:</0>'
			+ '<1>'
			+ '<0>CZK\u00a020,000 for employees –\u00a0the sum of all taxable incomes outside employment, and</0>'
			+ '<0>CZK\u00a050,000 for all other persons –\u00a0the sum of all taxable incomes.</0>'
			+ '</1>'
			+ '<0>Trading with Fondee may generate three different kinds of incomes.</0>'
			+ '<2><0>What kinds?</0></2>'
			+ '<2><0>1.</0> Income from dividends / capital income –\u00a0which is always subject to\u00a0tax</2>'
			+ '<2><0>2.</0> Income from the sale of\u00a0securities (ETFs) –\u00a0the main criterion is '
			+ 'the\u00a0amount of money you earned by selling securities over the\u00a0past year (not your '
			+ 'profits).</2>'
			+ '<3><0>a.</0> If your income is lower than CZK\u00a0100,000 for all securities (in\u00a0aggregate with '
			+ 'other sales in\u00a0addition to Fondee, which may include the\u00a0sale of your shares, bonds, units '
			+ 'etc., including amounts exempted from tax on\u00a0the\u00a0basis of the\u00a0time test, see point (c)), '
			+ 'the\u00a0income is exempted from tax and\u00a0you do not pay any tax.</3>'
			+ '<3><0>b.</0> If your income is higher than CZK\u00a0100,000, it becomes subject to tax, but you only '
			+ 'tax you profits, that is, the\u00a0difference between the\u00a0sale and the\u00a0purchase prices for '
			+ 'all the\u00a0securities you sold during the\u00a0past year. If the\u00a0result is a\u00a0loss, it '
			+ 'cannot be applied as tax loss.</3>'
			+ '<3><0>c.</0> If the\u00a0period between the\u00a0purchase and the\u00a0sale of\u00a0a\u00a0security '
			+ 'exceeded three years (time test), such sale is exempted from tax (the profit is not taxed and '
			+ 'a\u00a0generated loss cannot be applied as a\u00a0tax loss).</3>'
			+ '<2><0>3.</0> Other income –\u00a0over the\u00a0past year, we might have credited your investment '
			+ 'account with an\u00a0extraordinary bonus, for example, for recommending a\u00a0new client. Such income '
			+ 'is taxed as income from occasional activities; if the\u00a0aggregate of all your occasional incomes is '
			+ 'lower than CZK\u00a030,000, such incomes are exempted from tax and no tax is paid on them.</2>',
	},
};

const taxQuestionnaire = {
	start: 'Fill out the questionnaire',
	redo: 'Fill out the questionnaire again',
	introText: {
		1: {
			text: 'Thank you for completing the\u00a0questionnaire! On the\u00a0basis of your trading results from your '
				+ 'Fondee investments over the\u00a0past year, you have exceeded the\u00a0tax exemption limit for incomes '
				+ 'from the\u00a0sale of securities as well as the\u00a0tax exemption limit for occasional incomes and you '
				+ 'have the\u00a0obligation to file a\u00a0tax return if you have exceeded the\u00a0taxable income limit of '
				+ 'CZK\u00a050,000. Your capital and\u00a0other incomes are detailed in\u00a0the\u00a0attached statement for '
				+ 'the\u00a0preparation of your tax return.',
			note: '',
		},
		2: {
			text: 'On\u00a0the\u00a0basis of your trading results generated by your Fondee investments in the\u00a0past year, '
				+ 'you have exceeded the\u00a0tax exemption limit for incomes from the\u00a0sale of securities '
				+ 'and you have the\u00a0obligation to file a\u00a0tax return '
				+ 'if you have exceeded the\u00a0taxable income limit of CZK\u00a050,000.',
			note: 'Please, complete the\u00a0rest of the\u00a0questionnaire, so that we can generate the\u00a0statement you need for '
				+ 'your tax return.',
		},
		4: {
			text: 'On\u00a0the\u00a0basis of your trading results generated by your Fondee investments in the\u00a0past year you have '
				+ 'the\u00a0obligation to file a\u00a0tax return. You have exceeded the\u00a0taxable income limit of CZK\u00a050,000. ',
			note: 'Please, complete the\u00a0rest of the\u00a0questionnaire, so that we can generate the\u00a0statement you need for '
				+ 'your tax return.',
		},
		5: {
			text: 'On\u00a0the\u00a0basis of your trading results generated by your Fondee investments in the\u00a0past year you may '
				+ 'have the\u00a0obligation to file a\u00a0tax return. The\u00a0final answer will follow from further questions.',
			note: 'Please, complete the\u00a0rest of the\u00a0questionnaire, so that we can generate the\u00a0statement you need for '
				+ 'your tax return.',
		},
		6: {
			text: 'Based only on your trading results generated by your Fondee investments in the\u00a0past year you do not have '
				+ 'the\u00a0obligation to file a\u00a0tax return.',
			note: 'Please, complete the\u00a0rest of the\u00a0questionnaire, so that we can generate the\u00a0statement you need for '
				+ 'your tax return, if you are going to file it for other reasons (for example, if you are an\u00a0entrepreneur or if '
				+ 'you lease property).',
		},
	},
	text: {
		nonresident: 'This is where the\u00a0questionnaire ends for you, because it generates documents for filing a\u00a0tax '
			+ 'return in the\u00a0Czech Republic. If you are a\u00a0tax resident in a\u00a0country other than the\u00a0Czech Republic, '
			+ 'you will tax your Fondee investment incomes in the\u00a0country of your tax residency according to local regulations. '
			+ 'Our Fondee Statement of Incomes for the\u00a0past year will provide you with the\u00a0necessary information for that.',
		commercial: 'This is where the\u00a0questionnaire ends for you, because it generates documents for filing a\u00a0tax '
			+ 'return by private investors. Because you have included your Fondee investment portfolio into your business assets, '
			+ 'you tax these incomes as part of your independent activity (pursuant to Section\u00a07\u00a0ITA). Our Fondee Statement '
			+ 'of Incomes for the\u00a0past year will give you an\u00a0overview of the\u00a0achieved incomes and\u00a0the\u00a0associated '
			+ 'expenses in\u00a0the\u00a0past year, in\u00a0the\u00a0transaction statement section.',
		info: 'To complete your tax return, we recommend the website at\u00a0<0>www.mojedane.cz</0>, where you can fill in '
			+ 'and\u00a0send the\u00a0tax return form to the\u00a0Revenue Authority by electronic means.',
		infoLink: 'https://www.mojedane.cz/',
	},
	resultText: {
		1: 'Thank you for completing the\u00a0questionnaire! On\u00a0the\u00a0basis of your answers, it is possible to conclude '
			+ 'that your incomes from your Fondee investments classify as tax exempted incomes, because they do not exceed '
			+ 'the\u00a0limit for filing a\u00a0tax return.',
		2: 'Thank you for completing the\u00a0questionnaire! On\u00a0the\u00a0basis of your answers, we have prepared for you '
			+ 'a\u00a0complete statement for tax return purposes, showing your capital and\u00a0other incomes (incomes from '
			+ 'the\u00a0sale of securities as well as occasional incomes). Please, read carefully the\u00a0related notes.',
		3: 'Thank you for completing the\u00a0questionnaire! On\u00a0the\u00a0basis of your answers, we have prepared for you '
			+ 'a\u00a0complete statement for tax return purposes, showing your capital and\u00a0other incomes (occasional incomes meet '
			+ 'the\u00a0CZK\u00a030,000 tax exemption limit).',
		4: 'Thank you for completing the\u00a0questionnaire! On\u00a0the\u00a0basis of your answers, we have prepared for you '
			+ 'a\u00a0complete statement for the preparation of your tax return, showing your capital and\u00a0other incomes (occasional '
			+ 'incomes meet the\u00a0CZK\u00a030,000 tax exemption limit. Please, read carefully the\u00a0related notes.',
		5: 'Thank you for completing the\u00a0questionnaire! On\u00a0the\u00a0basis of your answers, we have prepared for you '
			+ 'a\u00a0complete statement for tax return purposes, showing your capital and\u00a0other incomes (incomes from '
			+ 'the\u00a0sale of securities meet the\u00a0CZK\u00a0100,000 tax exemption limit).',
		6: 'Thank you for completing the\u00a0questionnaire! On\u00a0the\u00a0basis of your answers, we have prepared for you '
			+ 'a\u00a0complete document for the\u00a0preparation of your tax return, showing your capital and\u00a0other incomes '
			+ '(incomes from the\u00a0sale of securities meet the\u00a0CZK\u00a0100,000 tax exemption limit). Please, read '
			+ 'carefully the\u00a0related notes.',
		7: 'Thank you for completing the\u00a0questionnaire! On\u00a0the\u00a0basis of your answers, we have prepared for you '
			+ 'a\u00a0complete statement for tax return purposes, showing your capital and\u00a0other incomes (incomes from '
			+ 'the\u00a0sale of securities meet the\u00a0CZK\u00a0100,000 tax exemption limit and\u00a0occasional incomes meet '
			+ 'the\u00a0CZK\u00a030,000 tax exemption limit).',
		8: 'Thank you for completing the\u00a0questionnaire! On\u00a0the\u00a0basis of your answers, we have prepared for you '
			+ 'a\u00a0complete document for the\u00a0preparation of your tax return, showing your capital and\u00a0other incomes '
			+ '(incomes from the\u00a0sale of securities meet the\u00a0CZK\u00a0100,000 tax exemption limit and\u00a0occasional '
			+ 'incomes meet the\u00a0CZK\u00a030,000 tax exemption limit). Please, read carefully the\u00a0related notes.',
	},
	download: 'Download the\u00a0tax return form',
	continue: 'Continue',
};

const topUp = {
	currency: 'In\u00a0what currency do you want to send the deposit?',
	portfolio: 'In\u00a0which portfolio do you want to deposit money?',
	modal: {
		title: 'Invest money',
		close: 'Close',
	},
	qr: 'QR code payment',
	payment: {
		title: 'Send payment to this account',
		account: {
			title: 'Account number',
			info: 'J&T Bank Broker Account',
			iban: 'IBAN',
			swift: 'BIC',
		},
		note: 'Enter message for recipient',
		notePension: 'Enter message for recipient or specific symbol',
		info: {
			owner: 'Account holder name: Fondee\u00a0a.\u00a0s.',
			address: 'Address: Rumunská\u00a022/28, 120\u00a000 Prague\u00a02',
		},
		warning: {
			account_one: 'Payment must be made from an\u00a0account',
			account_other: 'Payment must be made from\u00a0one of these accounts',
			accountChild: 'Please make the\u00a0payment from your verified account if <0>you do not wish to have a\u00a0deposit limit</0>.',
			transfer: 'Please note that a\u00a0transfer in\u00a0EUR must be entered as\u00a0a\u00a0SEPA payment.',
			banks: 'The account must be in your <0>own name</0>, so we cannot process payments from some third-party services '
				+ 'such as Wise, Revolut or\u00a0Monese that use collection accounts for sending payments. If you wish to use these '
				+ 'services to invest with Fondee, we recommend contacting us at <1>info@fondee.cz</1>.',
			note: 'If you do not specify a\u00a0beneficiary note, the\u00a0payment will be automatically allocated to your '
				+ 'primary portfolio within 2\u00a0days. You can change you primary portfolio in My Account.',
			pension: '<0>For employer contributions</0>, we accept payments directly from the\u00a0employer\'s account, '
				+ 'provided that the\u00a0company\'s identification number (IČO) is used as the variable symbol (VS), and '
				+ 'the\u00a0unique number of your pension portfolio (DIP) is included as a\u00a0reference or specific symbol (SS). '
				+ 'Once your pension portfolio is active, you can download Confirmation for the\u00a0employer '
				+ 'in\u00a0the\u00a0Documents section, which contains the payment details for contributions to your DIP.',
			employerNote: 'If your employer contributes or will contribute to your pension portfolio under the\u00a0DIP, '
				+ 'please add the\u00a0details in\u00a0My Account - Employers contributing to my DIP section.',
			child: '<0>Parents, legal guardians, other persons and the\u00a0children themselves with a\u00a0verified bank '
				+ 'account with Fondee can deposit money into children\'s portfolios without limit.'
				+ 'Deposits from other individuals, such as relatives or acquaintances, are limited to 23,000\u00a0CZK per\u00a0year '
				+ 'per\u00a0child. The deposit will be credited based on the child\'s unique portfolio number in\u00a0the\u00a0payment '
				+ 'reference (or as a\u00a0specific symbol).</0>',
		},
	},
	triggerButton: 'Invest money',
	short: 'deposit',
	employer: {
		open: 'Payment information for employers',
		modal: {
			title: 'Payment information for employers',
			send: 'Send to e-mail',
			success: 'Successfully sent',
		},
		payment: {
			note: 'Insert in the message to recipient or as a variable symbol',
			warning: {
				note: 'If the employer does not enter the correct message to recipient or variable symbol, '
					+ 'it may not be possible to allocate the payment to the portfolio. In this case, the payment '
					+ 'will be returned to the employer\'s account.',
			},
		},
	},
};

const tradingStates = {
	title: 'Trading',
	states: {
		initial: 'We are waiting for your investment of the initial deposit',
		deposit: 'The deposit of {{ sum }} is ready to be invested on {{ scheduled }}.',
		withdrawalPart: 'The withdrawal of {{ sum }} is ready to be processed on {{ scheduled }}. We will first sell some ETFs, if this is '
			+ 'necessary for the\u00a0withdrawal, then we will convert the withdrawal to the required currency and\u00a0send the money to your account.\n'
			+ 'The withdrawn sum will be transferred to your bank account within a\u00a0week.\n'
			+ 'You can cancel the withdrawal in the section <0>below on this page</0>.',
		withdrawalAll: 'The order to withdraw everything is ready to be\u00a0processed on {{ scheduled }}. We will first '
			+ 'sell the ETFs, then we will convert the withdrawn money to the required currency, and\u00a0then we will send the money to your account.\n'
			+ 'The withdrawn sum will be transferred to your bank account within a\u00a0week.\n'
			+ 'You can cancel the withdrawal in the section <0>below on this page</0>.',
		withdrawalCancel: 'The order to withdraw everything is ready to be\u00a0processed on {{ scheduled }}. We will first '
			+ 'sell the ETFs, then we will convert the withdrawn money to the required currency, and\u00a0then we will send the money to your account.\n'
			+ 'The withdrawn sum will be transferred to your bank account within a\u00a0week.'
			+ 'We will then close your investment account.\n'
			+ 'You can cancel the withdrawal in the section <0>below on this page</0>.',
		withdrawalTransferPart: 'You have entered an\u00a0order to move part of this portfolio to another portfolio. '
			+ 'The\u00a0order can be canceled <0>on\u00a0this page</0>.',
		withdrawalTransferAll: 'You have entered an\u00a0order to sell ETFs and move cash to another portfolio. '
			+ 'The\u00a0command can be canceled <0>on\u00a0this page</0>.',
		withdrawalPartClosed: 'The withdrawal of {{ sum }} is currently being processed. We will first sell some ETFs, if this is '
			+ 'necessary for the\u00a0withdrawal, then we will convert the withdrawal to the required currency and\u00a0send the money to your account.\n'
			+ 'The withdrawn sum will be transferred to your bank account within a\u00a0week.',
		withdrawalAllClosed: 'The withdrawal of all funds is currently underway. We will first sell the ETFs, then we will convert the withdrawn money '
			+ 'to the required currency, and\u00a0send it to your account. The withdrawn sum will be transferred '
			+ 'to your account within a\u00a0week',
		withdrawalCancelClosed: 'The withdrawal of all funds is currently underway. We will first sell the ETFs, then we will convert the withdrawn money '
			+ 'to the required currency, and\u00a0send it to your account.\n'
			+ 'The withdrawn sum will be transferred to your account within a\u00a0week. We will then '
			+ 'close your investment account.',
		withdrawalTransferPartClosed: 'A transfer between portfolios is being processed. '
			+ 'More information is displayed <0>below</0>.',
		withdrawalTransferAllClosed: 'A sell of ETFs and a\u00a0cash transfer is being processed. '
			+ 'More information is displayed <0>below</0>.',
		depositAndWithdrawal: 'Deposits and withdrawals planned for the same day will be processed at the same time.',
		profileChange: 'You have requested an investment profile change from\u00a0{{ profile }} to {{ planned }}. '
			+ 'On the nearest trading\u00a0day, we will rebalance your portfolio so that it best suits the new profile.',
		rebalancing: 'The next portfolio rebalancing (readjusting your portfolio to the selected investor profile) will '
			+ 'take place on {{ date }}.',
		rebalancingSkip: 'Your rebalancing function is deactivated. This means we will invest the deposits we receive from you '
			+ 'and send any withdrawals, but we will not automatically rebalance your portfolio. You can reactivate your portfolio rebalancing '
			+ 'in the section <0>below on this page</0>.',
		cash: 'You have requested a change in the ratio of the cash held from\u00a0{{ cash }} to {{ planned }}. On the\u00a0nearest '
			+ 'trading day, we will rebalance your portfolio so that it best suits the new cash ratio.',
		cashOver: 'The ratio of the cash held has been modified to {{ cash }}. You can change the\u00a0ratio again in '
			+ 'the\u00a0section <0>below on this page</0>. The change will take effect the next time your portfolio is rebalanced.',
		allocatedDeposit: 'We have received your deposit of {{ sum }} which is waiting to be invested. This will happen on the next trading '
			+ 'day, {{ date }}',
	},
};

const transparentAccount = {
	pretitle: 'Total value of Eva and Honza´s portfolio',
	netGrowth: 'Value growth',
	netGrowthRatio: 'Value growth in\u00a0%',
	type: 'Investor type',
	initialDeposit: 'Initial deposit',
	monthlyDeposit: 'Monthly deposit',
	note: '',
};

const unsubscribe = {
	default: {
		title: 'Unsubscribe from marketing e-mails',
		text: 'Are you sure you want to unsubscribe from\u00a0marketing e-mails? You can re-subscribe '
			+ 'in the\u00a0<0>client\u00a0zone</0>.',
		button: 'Logout',
	},
	success: {
		title: 'Logged out',
		text: 'If you decide to re-subscribe to marketing e-mails, you can activate them '
			+ 'in the\u00a0client\u00a0zone under\u00a0<0>My\u00a0Account</0>.',
		button: 'Login',
	},
};

export {
	account,
	ageCheckBlock,
	allPortfoliosCheck,
	anchors,
	appBanner,
	blog,
	blogPost,
	campaign,
	childAgeCheck,
	clientCheck,
	clientHeader,
	contact,
	contactForm,
	contactUs,
	cookieConsent,
	cookies,
	countryCheck,
	dashboard,
	documents,
	documentUrls,
	email,
	emailUs,
	emailVerification,
	employer,
	error,
	error404,
	faq,
	feesChart,
	feesComparison,
	feesHero,
	feesProducts,
	findOutInfoBox,
	footer,
	footerCta,
	forgotPassword,
	forms,
	general,
	header,
	historyChart,
	historyFilter,
	historyOverview,
	historyProgress,
	historyTransactions,
	homepageAbout,
	homepageHero,
	homepageJT,
	homepageProducts,
	homepageServices,
	howItWorksHero,
	howItWorksInvestment,
	howItWorksPrinciples,
	howItWorksRebalancing,
	howItWorksSchema,
	investorProfileAvatar,
	investorProfileChoice,
	investorProfileDetail,
	investorProfilePreview,
	languageSwitcher,
	login,
	media,
	meta,
	offers,
	onboarding,
	ourApproachAccount,
	ourApproachHero,
	ourApproachTeam,
	portfolio,
	portfolioComposition,
	portfolioFeesEtfs,
	portfolioFeesOverview,
	portfolioTable,
	privacyPolicy,
	products,
	productAccount,
	productSelect,
	profileAvatar,
	referrals,
	register,
	resetPassword,
	scrollToTop,
	signAgreement,
	signNewAgreement,
	studentConfirmation,
	survey,
	taxes,
	taxQuestionnaire,
	topUp,
	tradingStates,
	transparentAccount,
	unsubscribe,
};
